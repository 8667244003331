import { useState, useEffect } from 'react';

export const useAuth = () => {
  // Initialize isAuthenticated state based on local storage or default to false
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedUserData = localStorage.getItem('userData');
    //console.log('Stored User Data:', storedUserData); // Add this line for debugging
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    return userData ? userData.isAuthenticated : false;
  });

  // Method to set isAuthenticated to true, indicating successful authentication
  const authenticate = (userId: string) => {
    setIsAuthenticated(true);
    localStorage.setItem('userData', JSON.stringify({ isAuthenticated: true }));
    console.log('User authenticated:', userId); // Add this line for debugging
  };

  // Method to set isAuthenticated to false, indicating logout or failed authentication
  const unauthenticate = () => {
    setIsAuthenticated(false);
    localStorage.setItem('userData', JSON.stringify({ isAuthenticated: false }));
  };

  useEffect(() => {
    // Update isAuthenticated in local storage whenever it changes
    localStorage.setItem('userData', JSON.stringify({ isAuthenticated }));
  }, [isAuthenticated]);

  return {
    isAuthenticated,
    authenticate,
    unauthenticate,
  };
};
