import HttpService from '../../../services/api/HttpService';
import { Users, UserStatus } from '../../../types/models/Users';
import { Pageable } from '../../../types/models/Pageable';

interface AddToCartPayload {
    offerId: string;
    userId: string;
    contactToCharge: string;
}

interface ApiResponse {
    data: Users[];
    pageable: Pageable;
    totalPages: number;
    totalElements: number;
    last: boolean;
    numberOfElements: number;
    size: number;
    number: number;
    sort: {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    };
    first: boolean;
    empty: boolean;
}

class UsersManagementRepository {
    private httpService: HttpService;


    constructor() {
        this.httpService = new HttpService();
        this.httpService.service();
    }

    async fetchUsers(
      page: number = 1,
      pageSize: number = 10,
      filter: string = '',
      status: UserStatus[] = [ 'PENDING', 'BLOCKED', 'ACTIVE', 'REMOVED']
    ): Promise<ApiResponse> {
        try {
            // Construct the query parameters
            const queryParams: any = { page, pageSize, filter };
            if (status && status.length > 0) {
                queryParams.status = status.join(',');
            }

            const response = await this.httpService.get<any>(`/management/users?page=${page}&pageSize=${pageSize}&filter=${filter}&status=${status}`, {
               // params: queryParams
            });
            const responseData = response.data;

            // Extract data and pagination information from the response
            const { content, pageable, totalPages, totalElements } = responseData;

            // Map the data to your Users objects
            const users: Users[] = content.map((user: any) => ({
                id: user.id,
                name: user.name,
                photoURL: user.photoURL,
                email: user.email,
                phone: user.phone,
                agencyId: user.agencyId,
                createdAt: user.createdAt,
                status: user.status,
                emailNotificationsAllowed: user.emailNotificationsAllowed,
                smsNotificationsAllowed: user.smsNotificationsAllowed,
                emailConfirmed: user.emailConfirmed,
                phoneNumberConfirmed: user.phoneNumberConfirmed,
                roleIds: user.roleIds
            }));

            return { data: users, ...pageable , totalPages, totalElements };
        } catch (error) {
            console.error('Failed to fetch users:', error);
            throw new Error('Failed to fetch users');
        }
    }
}

export default UsersManagementRepository;
