import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SidebarLayout from 'src/layouts/SidebarLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Overview from 'src/content/overview';
import Crypto from 'src/content/principal';
import Messenger from 'src/content/applications/Messenger';
import Transactions from 'src/content/applications/Transactions';
import UserProfile from 'src/content/applications/Users/profile';
import UserSettings from 'src/content/applications/Users/settings';
import Buttons from 'src/content/pages/Components/Buttons';
import Modals from 'src/content/pages/Components/Modals';
import Accordions from 'src/content/pages/Components/Accordions';
import Tabs from 'src/content/pages/Components/Tabs';
import Badges from 'src/content/pages/Components/Badges';
import Tooltips from 'src/content/pages/Components/Tooltips';
import Avatars from 'src/content/pages/Components/Avatars';
import Cards from 'src/content/pages/Components/Cards';
import Forms from 'src/content/pages/Components/Forms';
import Status404 from 'src/content/pages/Status/Status404';
import Status500 from 'src/content/pages/Status/Status500';
import StatusComingSoon from 'src/content/pages/Status/ComingSoon';
import StatusMaintenance from 'src/content/pages/Status/Maintenance';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useAuth } from './hooks/useAuth';
import SignIn from './content/pages/SignIn/SignIn';
import ApplicationsUsers from './content/applications/UsersManagement';
import PromoOffersCubacel from './content/pages/offers/Cubacel';
import DashboardPrincipal from 'src/content/principal';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import NetworkCalls from './content/applications/NetworkCalls';
import DingConnect from './content/dingconnect';
import ManagementUserProfile from 'src/content/applications/Users/profile';

const firebaseConfig = {
  apiKey: "AIzaSyB-gmH2TguG-eX57M74cWVrDgfUvsHgCF0",
  authDomain: "qbolacel-rey.firebaseapp.com",
  projectId: "qbolacel-rey",
  storageBucket: "qbolacel-rey.appspot.com",
  messagingSenderId: "605748001046",
  appId: "1:605748001046:web:2063670346306fb3aef837",
  measurementId: "G-LLZB4NF112"
};


function App() {
  const { isAuthenticated } = useAuth();

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/404" element={<Status404 />} />
            <Route path="/500" element={<Status500 />} />
            <Route path="/maintenance" element={<StatusMaintenance />} />
            <Route path="/coming-soon" element={<StatusComingSoon />} />

            {/* Routes under SidebarLayout */}
            <Route
              path="/"
              element={isAuthenticated ? <SidebarLayout /> : <Navigate to="/signin" replace />}
            >
              <Route path="/principal" element={<DashboardPrincipal />} />
              <Route path="/" element={<DashboardPrincipal />} />
              <Route
                path="/status/*"
                element={
                  <Routes>
                    <Route index element={<Navigate to="404" replace />} />
                    <Route path="/404" element={<Status404 />} />
                    {/* Add more status routes if needed */}
                  </Routes>
                }
              />
              <Route
                path="/dashboards/*"
                element={
                  <Routes>
                    <Route index element={<Navigate to="crypto" replace />} />
                    <Route path="/crypto" element={<Crypto />} />
                    <Route path="/messenger" element={<Messenger />} />
                  </Routes>
                }
              />
              <Route
                path="/offers/*"
                element={
                  <Routes>
                    <Route index element={<Navigate to="cubacel" replace />} />
                    <Route path="/cubacel-nauta" element={<PromoOffersCubacel />} />
                    <Route path="/users" element={<ApplicationsUsers />} />
                  </Routes>
                }
              />
              <Route path="/user/profile/:id" element={<ManagementUserProfile />}  />

              <Route
                path="/profile/*"
                element={
                  <Routes>
                    <Route index element={<Navigate to="details" replace />} />
                    <Route path="/details" element={<UserProfile />} />
                    <Route path="/settings" element={<UserSettings />} />
                  </Routes>
                }
              />

              <Route
                path="/management/*"
                element={
                  <Routes>
                    <Route index element={<Navigate to="users" replace />} />
                    <Route path="/transactions" element={<Transactions />} />
                    <Route path="/users" element={<ApplicationsUsers />} />
                    <Route path="/dingconnect" element={<DingConnect />} />
                    
                    <Route
                      path="/profile/*"
                      element={
                        <Routes>
                          <Route index element={<Navigate to="details" replace />} />
                          <Route path="/details" element={<UserProfile />} />
                          <Route path="/settings" element={<UserSettings />} />
                        </Routes>
                      }
                    />
                  </Routes>
                }
              />

              <Route
                path="/administration/*"
                element={
                  <Routes>
                    <Route index element={<Navigate to="users" replace />} />
                    <Route path="/networkCalls" element={<NetworkCalls />} />

                  </Routes>
                }
              />
              <Route
                path="/components/*"
                element={
                  <Routes>
                    <Route index element={<Navigate to="buttons" replace />} />
                    <Route path="/buttons" element={<Buttons />} />
                    <Route path="/modals" element={<Modals />} />
                    <Route path="/accordions" element={<Accordions />} />
                    <Route path="/tabs" element={<Tabs />} />
                    <Route path="/badges" element={<Badges />} />
                    <Route path="/tooltips" element={<Tooltips />} />
                    <Route path="/avatars" element={<Avatars />} />
                    <Route path="/cards" element={<Cards />} />
                    <Route path="/forms" element={<Forms />} />
                  </Routes>
                }
              />
            </Route>

            {/* Catch-all route */}
            <Route path="*" element={<Status404 />} />
          </Routes>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );


}

export default App;


