import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  DialogContent,
  DialogContentText,
  Button
} from '@mui/material';
import Label from 'src/components/Label';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BulkActions from './BulkActions';
import { Users, UserStatus } from '../../../types/models/Users';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { UserRole } from '../../../types/enums';
import { NetworkCalls } from 'src/types/models/NetworkCalls';
import NetworkCallsRepository from './NetworkCallsRepository';
import { formatDate } from 'src/utils/utils';

interface NetworkCallsTablesProps {
  className?: string,
  // networkCallsToShow: NetworkCalls[],
  // pageNumber: number,
  // itemsLimit: number,
  // handleNetworkCall?: (page: number, limit: number) => void,
  // totalItems?: number
}

interface Filters {
  status?: UserStatus;
}

const getStatusLabel = (userStatus: UserStatus): JSX.Element => {
  const map = {
    REMOVED: {
      text: 'Borrado',
      color: 'error'
    },
    BLOCKED: {
      text: 'Bloqueado',
      color: 'error'
    },
    ACTIVE: {
      text: 'Activo',
      color: 'success'
    },
    PENDING: {
      text: 'Pendiente',
      color: 'warning'
    }
  };

  const { text, color }: any = map[userStatus];

  return <Label color={color}>{text}</Label>;
};

const getRoleLabel = (userStatus: UserRole): JSX.Element => {
  const map = {
    ADMIN: {
      text: 'Administrador',
      color: 'error'
    },
    STAFF: {
      text: 'Staff',
      color: 'primary'
    },
    USER: {
      text: 'Usuario',
      color: 'success'
    },
    SUPERVISOR: {
      text: 'Supervisor',
      color: 'warning'
    }
  };

  const { text, color }: any = map[userStatus];

  return <Label color={color}>{text}</Label>;
};

function getUserRole(roleId: string): UserRole {
  // Map roleId to UserRole

  const roleIdToUserRoleMap: { [key: string]: UserRole } = {
    '65f53af8d470c2149a1b35dd': UserRole.USER,
    '65f53b1cd470c2149a1b35df': UserRole.STAFF,
    '65f53b26d470c2149a1b35e1': UserRole.SUPERVISOR,
    '65f53b55d470c2149a1b35e3': UserRole.ADMIN

  };

  // Get UserRole based on roleId
  const userRole = roleIdToUserRoleMap[roleId] || UserRole.USER;

  return userRole;
}

const getNotificationsLabel = (emailStatus: boolean, smsStatus: boolean): JSX.Element => {
  const mapEmail = {
    false: {
      text: 'Correo : No',
      color: 'error'
    },
    true: {
      text: 'Correo : Si',
      color: 'success'
    }
  };

  const mapSMS = {
    false: {
      text: 'SMS : No',
      color: 'error'
    },
    true: {
      text: 'SMS : Si',
      color: 'success'
    }
  };

  const { text: emailText, color: emailColor } = mapEmail[emailStatus.toString()];
  const { text: smsText, color: smsColor } = mapSMS[smsStatus.toString()];

  return (
    <div>
      <Label color={emailColor as 'error' | 'success'}>{emailText}</Label>
      <Label color={smsColor as 'error' | 'success'}>{smsText}</Label>
    </div>
  );
};

const applyFilters = (
  users: NetworkCalls[],
  filters: Filters
): NetworkCalls[] => {
  return users.filter((users) => {
    let matches = true;

    /*  if (filters.status && users.status !== filters.status) {
       matches = false;
     } */

    return matches;
  });
};

const applyPagination = (
  networkCalls: NetworkCalls[],
  pageNumber: number,
  itemsLimit: number
): NetworkCalls[] => {
  return networkCalls.slice(pageNumber * itemsLimit, pageNumber * itemsLimit + itemsLimit);
};

// const applyPagination = (
//   networkCalls: NetworkCalls[],
//   pageNumber: number,
//   itemsLimit: number
// ): NetworkCalls[] => {
//   const startIndex = pageNumber * itemsLimit;
//   const endIndex = startIndex + itemsLimit;
//   return networkCalls.slice(startIndex, endIndex);
// };

const NetworkCallsTables: FC<NetworkCallsTablesProps> = ({
                                                           // networkCallsToShow,
                                                           // pageNumber,
                                                           // itemsLimit,
                                                           // handleNetworkCall,
                                                           // totalItems
                                                         }) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedUsers.length > 0;

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  const [networkCalls, setNetworkCalls] = useState<NetworkCalls[]>([]);
  const [userToEdit, setUserToEdit] = useState(null);
  const networkCallsRepository = new NetworkCallsRepository();
  const [openDeleteDialogs, setOpenDeleteDialogs] = useState<{ [id: string]: boolean }>({});
  const roles = localStorage.getItem('rid');

  const [totalItems, setTotalItems] = useState<number>(0);


  React.useEffect(() => {

    networkCallsRepository.fetchNetworkCalls(page, limit).then((data) => {
      setNetworkCalls(data.data)
      setTotalItems(data.totalElements);
    }).catch((error) => {
      console.error('Failed to fetch users:', error);
    });

  }, [page, limit]);

  const handleDeleteClickOpen = (index: number) => {
    setOpenDeleteDialogs((prevState) => ({
      ...prevState,
      [networkCalls[index].id]: true
    }));
  };

  const handleDeleteClickClose = (index: number) => {
    setOpenDeleteDialogs((prevState) => ({
      ...prevState,
      [networkCalls[index].id]: false
    }));
  };


  const handleDeleteUser = (user, index) => {
    /* usersRepository.deleteUser(user.id).then(data => {
      handleOffersUpdate()
      handleDeleteClickClose(index);
    }).catch(error => {
      console.error('Error deleting promo offer:', error);
      // Handle error as needed
    }); */
  };

  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  // useEffect(() => {
  //   setNetworkCalls(networkCallsToShow);
  // }, [networkCallsToShow]);

  const statusOptions = [
    {
      id: 'all',
      name: 'Todos'
    },
    {
      id: 'ACTIVE',
      name: 'Activos'
    },
    {
      id: 'PENDING',
      name: 'Pendientes'
    },
    {
      id: 'REMOVED',
      name: 'Borrados'
    },
    {
      id: 'BLOCKED',
      name: 'Bloqueados'
    }
  ];

  const handleOffersUpdate = () => {

  };


  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  /* const handleSelectedUsers = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedUsers(
      event.target.checked
        ? networkCalls.map((users) => users.id)
        : []
    );
  }; */

  /* const handleSelectOneUser = (
    event: ChangeEvent<HTMLInputElement>,
    userId: string
  ): void => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelected) => [
        ...prevSelected,
        userId
      ]);
    } else {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((id) => id !== userId)
      );
    }
  }; */

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
    //handleNetworkCall(newPage, limit);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
    //handleNetworkCall(page, parseInt(event.target.value));
  };

  const getUserLabel = (userStatus : UserRole, name : String): JSX.Element => {
    const map = {
      ADMIN: {
        text: name,
        color: 'error'
      },
      STAFF: {
        text: name,
        color: 'primary'
      },
      USER: {
        text: name,
        color: 'success'
      },
      SUPERVISOR: {
        text: name,
        color: 'warning'
      }
    };

    const { text, color }: any = map[userStatus];

    return <Label color={color}>{text}</Label>;
  };

  // const filteredNetworkCalls = applyFilters(networkCalls, filters);
  // const paginatedNetworkCalls = applyPagination(
  //   networkCalls,
  //   1,
  //   6
  //   //page,
  //   //limit
  // );
  /*  const selectedSomeUsers =
     selectedUsers.length > 0 &&
     selectedUsers.length < networkCalls.length;
   const selectedAllUsers =
     selectedUsers.length === networkCalls.length; */
  const theme = useTheme();

  return (
    <Card>
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Estado</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Estado"
                  autoWidth
                >

                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          title="Usuarios Registrados"
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Request y Fecha</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell>Plataforma</TableCell>
              <TableCell>Sistema Operativo</TableCell>
              <TableCell>Origen</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {networkCalls.map((networkCall, index) => {
              const isUserSelected = selectedUsers.includes(
                networkCall.id
              );
              //const roleId = user.roleIds[0];
              return (
                <TableRow
                  hover
                  key={networkCall.id}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {networkCall.url}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {formatDate(networkCall.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {networkCall.userInfo && networkCall.userInfo.id ? (
                        getUserLabel(getUserRole(networkCall.userInfo.role), networkCall.userInfo.name)
                      ) : (
                        <Label>Unauthenticated</Label>
                      )}

                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {networkCall.deviceType}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {networkCall.platform}
                    </Typography>

                  </TableCell>
                  {/* <TableCell align="right">

                    {getRoleLabel(getUserRole(roleId))}
                  </TableCell>

                  <TableCell align="right">
                    {getNotificationsLabel(networkCall.emailNotificationsAllowed, networkCall.smsNotificationsAllowed)}
                  </TableCell>

                  <TableCell align="right">
                    {getStatusLabel(networkCall.status)}
                  </TableCell> */}
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {networkCall.systemOs}
                    </Typography>

                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {networkCall.origin}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {networkCall.referer}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">

                    {(getUserRole(roles) === UserRole.ADMIN) && (

                      <>
                        <Tooltip title="Borrar llamada de red" arrow>
                          <IconButton
                            sx={{
                              '&:hover': { background: theme.colors.error.lighter },
                              color: theme.palette.error.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => handleDeleteClickOpen(index)}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Dialog
                          open={openDeleteDialogs[index]}
                          onClose={() => handleDeleteClickClose(index)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {'Eliminar la información del usuario??'}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Estás seguro que desea eliminar la información de la llamada de red? Este cambio no se
                              puede deshacer.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => handleDeleteClickClose(index)}>Cancelar</Button>
                            <Button
                              onClick={() => handleDeleteUser(networkCall, index)} autoFocus>
                              Eliminar
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>

                    )}

                  </TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={totalItems}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 30]}
        />
      </Box>
    </Card>
  );
};

export default NetworkCallsTables;
