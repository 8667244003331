import { UserRole } from "src/types/enums";

export function formatDate(dateString: string): string {
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  
    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? "P. M." : "A. M.";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
  
    const formattedDate = `${month} ${day}, ${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  
    return formattedDate;
  }

export function formatDateFromTimeStamp(timestamp: number): string {
    if (isNaN(timestamp)) {

        return "Invalid timestamp";
    }

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const date = new Date(timestamp);

    // Check if date is valid
    if (isNaN(date.getTime())) {
        return "Invalid date";
    }

    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? "P. M." : "A. M.";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    const formattedDate = `${month} ${day}, ${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

    return formattedDate;
}


export function formatUtcToTimestamp(utcDateString: string): string {
    const date = new Date(utcDateString);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function formatTimestampToUtc(timestamp: number): string {
    if (timestamp === undefined || timestamp === null) {
        throw new Error("Timestamp is undefined or null");
    }

    const date = new Date(timestamp); // Create a date object from the timestamp

    // Format the date to "yyyy-mm-dd"
    const formattedDate = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}`;

    return formattedDate;
}




// // Example usage:
// const utcString = "2024-09-24T14:35:45Z";
// console.log(formatUtcToTimestamp(utcString));
// // Output: "2024-09-24 14:35:45"


export const getUserRole = (roleId: string): UserRole => {
    const roleIdToUserRoleMap: { [key: string]: UserRole } = {
        "65f53af8d470c2149a1b35dd": UserRole.USER,
        "65f53b1cd470c2149a1b35df": UserRole.STAFF,
        "65f53b26d470c2149a1b35e1": UserRole.SUPERVISOR,
        "65f53b55d470c2149a1b35e3": UserRole.ADMIN,
    };

    return roleIdToUserRoleMap[roleId] || UserRole.USER;
};

export const getRoleLabelFromRoleId = (roleId: string): string => {
    const userRole = getUserRole(roleId);
    return getRoleLabel(userRole);
};

const getRoleLabel = (userStatus: UserRole): string => {
    const map: { [key in UserRole]: string } = {
        ADMIN: 'Administrador',
        STAFF: 'Staff',
        USER: 'Usuario',
        SUPERVISOR: 'Supervisor',
    };

    return map[userStatus];
}

export const formatUSD = (amount: number): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    }).format(amount);
};

