import { Box, Container, Link, Typography, styled } from '@mui/material';

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1">
            &copy; 2024 - Panel de control para la administración de la plataforma{' '}
            <Link
              href="https://qbolacel.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              QbolaCel.com
            </Link>
          </Typography>

        </Box>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
        >
          Creado por{' '}
          <Link
            href="https://renielgonzalez.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Reniel Gonzalez
          </Link>
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
