import React, { FC, ChangeEvent, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader, Button, DialogContent, DialogContentText
} from '@mui/material';

import Label from 'src/components/Label';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import BulkActions from './BulkActions';
import { PromoOffer, PromoOfferStatus } from '../../../../types/models/PromoOffer';
import ButtonGroup from '@mui/material/ButtonGroup';
import PromoOfferDialog from '../../Components/Modals/PromoOfferDialog';
import OffersRepository from '../OffersRepository';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { UserRole } from '../../../../types/enums';
import { formatDate, formatDateFromTimeStamp } from '../../../../utils/utils';

interface CubacelTableProps {
  className?: string;
  promoOffersList: PromoOffer[];
}

interface Filters {
  status?: PromoOfferStatus;
}


export const TopUpType = {
  NAUTA: 'NAUTA',
  CUBACEL: 'CUBACEL',
  ALL: 'ALL'
};

const getStatusLabel = (promoOfferStatus:  PromoOfferStatus): JSX.Element => {
  const map = {
    COMING: {
      text: 'Pendiente',
      color: 'warning'
    },
    EXPIRED: {
      text: 'Expirada',
      color: 'error'
    },
    DEACTIVATED: {
      text: 'Desactivada',
      color: 'error'
    },
    ACTIVE: {
      text: 'Activa',
      color: 'success'
    }
  };

  const { text, color }: any = map[promoOfferStatus] || { text: 'Estado desconocido', color: 'warning' };

  return <Label color={color}>{text}</Label>;
};
const applyFilters = (
  promoOffers: PromoOffer[],
  filters: Filters,
  selectedOfferTypeFilter: string
): PromoOffer[] => {
  return promoOffers.filter((promoOffer) => {
    let matches = true;

    // Check if the status filter matches
    if (filters.status && promoOffer.status !== filters.status) {
      matches = false;
    }

    // Check if the type matches the selectedOfferTypeFilter
    if (selectedOfferTypeFilter !== TopUpType.ALL) {
      if (promoOffer.type !== selectedOfferTypeFilter) {
        matches = false;
      }
    }

    return matches;
  });
};


const applyPagination = (
  promoOffers: PromoOffer[],
  page: number,
  limit: number
): PromoOffer[] => {
  return promoOffers.slice(page * limit, page * limit + limit);
};

const CubacelOffersTable: FC<CubacelTableProps> = ({ promoOffersList }) => {
  const [selectedOffers, setSelectedOffers] = useState<string[]>(
    []
  );
  const [selectedOfferTypeFilter, setSelectedOfferTypeFilter] = useState(TopUpType.CUBACEL);

  const selectedBulkActions = selectedOffers.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [openEditOfferDialog, setOpenEditOfferDialog] = useState(false);
  const [promoOfferToEdit, setPromoOfferToEdit] = useState(null);
  const [promoOffers, setPromoOffers] = useState<PromoOffer[]>(promoOffersList);
  const [openDeleteDialogs, setOpenDeleteDialogs] = useState<boolean[]>(new Array(promoOffers.length).fill(false));

  const roles = localStorage.getItem("rid");


  const handleOpenDialog = () => {
    setOpenEditOfferDialog(true);  // Set to true when you want to open the dialog
  };

  const handleDeleteClickOpen = (index: number) => {
    const newOpenDeleteDialogs = [...openDeleteDialogs];
    newOpenDeleteDialogs[index] = true;
    setOpenDeleteDialogs(newOpenDeleteDialogs);
  };

  const handleDeleteClickClose = (index: number) => {
    const newOpenDeleteDialogs = [...openDeleteDialogs];
    newOpenDeleteDialogs[index] = false;
    setOpenDeleteDialogs(newOpenDeleteDialogs);
  };

  // const handleDeleteClickOpen = (index: number) => {
  //   const newOpenDeleteDialogs = [...openDeleteDialogs];
  //   newOpenDeleteDialogs[index] = true;
  //   setOpenDeleteDialogs(newOpenDeleteDialogs);
  // };
  //
  // const handleDeleteClickClose = (index: number) => {
  //   const newOpenDeleteDialogs = [...openDeleteDialogs];
  //   newOpenDeleteDialogs[index] = false;
  //   setOpenDeleteDialogs(newOpenDeleteDialogs);
  // };

  const handleDeleteOffers = (promoOffer, index) =>{
    offersRepository.deletePromoOffer(promoOffer.id).then(data => {
      handleOffersUpdate()
      handleDeleteClickClose(index);
    }).catch(error => {
      console.error('Error deleting promo offer:', error);
      // Handle error as needed
    });
  };


  const [filters, setFilters] = useState<Filters>({

    status: null
  });
  const offersRepository = new OffersRepository();

  React.useEffect(() => {
    setPromoOffers(promoOffersList);

  }, [promoOffersList]);

  const statusOptions = [
    {
      id: 'all',
      name: 'Todas'
    },
    {
      id: 'ACTIVE',
      name: 'Activas'
    },
    {
      id: 'COMING',
      name: 'Pendientes'
    },
    {
      id: 'DEACTIVATED',
      name: 'Deactivadas'
    },
    {
      id: 'EXPIRED',
      name: 'Expiradas'
    }
  ];

  const handleOffersUpdate = () => {


    const fetchPromoOffers = async () => {
      offersRepository.fetchPromos().then((data) => {
        setPromoOffers(data.data)

      }).catch((error) => {
        console.error('Failed to fetch offers:', error);
      });
    };

    fetchPromoOffers();

  };
  // Function to open the dialog for editing a promo offer
  const handleEditPromoOffer = (promoOffer) => {
    setPromoOfferToEdit(promoOffer);
    setOpenEditOfferDialog(true);
  };

  const handleDeletePromoOffer = (promoOffer) =>{
    offersRepository.deletePromoOffer(promoOffer.id).then(data =>
      {
        console.log('Promo Offer deleted:', data);
        handleOffersUpdate();
      }
    ).catch(error => {
      console.error('Error deleting promo offer:', error);
      // Handle error as needed
    });
  };
  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenEditOfferDialog(false);
    setPromoOfferToEdit(null);
  };

  const handleFilterSelect = (filter) => {
    setSelectedOfferTypeFilter(filter);
  };
  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectedUsers = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedOffers(
      event.target.checked
        ? promoOffers.map((users) => users.id)
        : []
    );
  };

  const handleSelectOneUser = (
    event: ChangeEvent<HTMLInputElement>,
    userId: string
  ): void => {
    if (!selectedOffers.includes(userId)) {
      setSelectedOffers((prevSelected) => [
        ...prevSelected,
        userId
      ]);
    } else {
      setSelectedOffers((prevSelected) =>
        prevSelected.filter((id) => id !== userId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredUsers = applyFilters(promoOffers, filters, selectedOfferTypeFilter);
  const paginatedUsers = applyPagination(
    filteredUsers,
    page,
    limit
  );

  function getUserRole(roleId: string): UserRole {
    // Map roleId to UserRole

    const roleIdToUserRoleMap: { [key: string]: UserRole } = {
      "65f53af8d470c2149a1b35dd": UserRole.USER,
      "65f53b1cd470c2149a1b35df": UserRole.STAFF,
      "65f53b26d470c2149a1b35e1": UserRole.SUPERVISOR,
      "65f53b55d470c2149a1b35e3": UserRole.ADMIN,

    };

    // Get UserRole based on roleId
    const userRole = roleIdToUserRoleMap[roleId] || UserRole.USER;

    return userRole;
  }

  const selectedSomeUsers =
    selectedOffers.length > 0 &&
    selectedOffers.length < promoOffers.length;
  const selectedAllUsers =
    selectedOffers.length === promoOffers.length;
  const theme = useTheme();

  return (
    <Card>
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )}
      {!selectedBulkActions && (
        <div style={{ display: 'flex', alignItems: 'center', padding: '16px 24px' }}>
          <Typography variant="h6" component="div" style={{ marginRight: 'auto' }}>
            Ofertas activas y pasadas
          </Typography>

          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <ButtonGroup
              color="primary"
              disabled={false}
              size="medium"
              variant="outlined"
              style={{ margin: '0 auto' }}
            >
              {/*<Button>Cubacel</Button>*/}
              {/*<Button>Nauta</Button>*/}
              {/*<Button>Todas</Button>*/}
              <Button
                onClick={() => handleFilterSelect(TopUpType.CUBACEL)}
                variant={selectedOfferTypeFilter === TopUpType.CUBACEL ? 'contained' : 'outlined'}
              >
                Cubacel
              </Button>
              <Button
                onClick={() => handleFilterSelect(TopUpType.NAUTA)}
                variant={selectedOfferTypeFilter === TopUpType.NAUTA ? 'contained' : 'outlined'}
              >
                Nauta
              </Button>
              <Button
                onClick={() => handleFilterSelect(TopUpType.ALL)}
                variant={selectedOfferTypeFilter === TopUpType.ALL ? 'contained' : 'outlined'}
              >
                Todas
              </Button>
            </ButtonGroup>
          </div>

          <FormControl style={{ width: 180 }} variant="outlined">
            <InputLabel>Estado</InputLabel>
            <Select
              value={filters.status || 'all'}
              onChange={handleStatusChange}
              label="Estado"
              autoWidth
            >
              {statusOptions.map((statusOption) => (
                <MenuItem key={statusOption.id} value={statusOption.id}>
                  {statusOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>


      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
            
              <TableCell>Detalles de Oferta</TableCell>
              {/*<TableCell>Agencia</TableCell>*/}
              <TableCell>Descripción</TableCell>
              <TableCell align="center">Etiquetas</TableCell>
              <TableCell align="center">Comentarios</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((promoOffer, index) => {
              const isUserSelected = selectedOffers.includes(
                promoOffer.id
              );

              return (
                <TableRow
                  hover
                  key={promoOffer.id}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {promoOffer.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>

                      {formatDateFromTimeStamp(promoOffer.endingDate)}
                    </Typography>
                  </TableCell>
                  <TableCell>

                    <Tooltip title={promoOffer.description} arrow>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '120px', // Adjust this based on your line height
                        maxWidth:'350px',
                        overflow: 'hidden'
                      }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4, // Limit to 2 lines
                          }}
                        >
                          {promoOffer.description}
                          {/*{promoOffer.description.length > 30*/}
                          {/*  ? `${promoOffer.description.slice(0, 30)}...`*/}
                          {/*  : promoOffer.description*/}
                          {/*}*/}
                        </Typography>
                      </div>
                    </Tooltip>
                  </TableCell>

                  <TableCell align="center">
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        padding: '4px 8px', // Reduced padding for a smaller size
                        borderRadius: '8px', // Smaller border radius for a more compact look
                        fontSize: '0.75rem', // Adjust font size to make the text smaller
                        color: '#FFFFFF', // Set text color to white
                        minWidth:'150px',
                        backgroundColor: `#${Number(promoOffer.infoLabel?.color).toString(16).substring(2)}`, // Convert to valid hex and remove alpha
                      }}
                    >
                      {promoOffer.infoLabel?.text}
                    </Box>
                  </TableCell>


                  <TableCell align="center">
                    <Tooltip title={promoOffer.comments} arrow>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '120px', // Adjust this based on your line height
                        maxWidth:'400px',
                        overflow: 'hidden'
                      }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4, // Limit to 2 lines
                          }}
                        >
                          {promoOffer.comments}
                        </Typography>
                      </div>
                    </Tooltip>
                  </TableCell>



                  <TableCell align="center">
                    {getStatusLabel(promoOffer.status)}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Información de Oferta" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {(getUserRole(roles) === UserRole.ADMIN || getUserRole(roles) === UserRole.SUPERVISOR || getUserRole(roles) === UserRole.STAFF) && (
                      <Tooltip title="Editar Oferta" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                          onClick={() => handleEditPromoOffer(promoOffer)} // Pass promo offer data to edit
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}

                    {(getUserRole(roles) === UserRole.ADMIN) && (
                      <>
                        <Tooltip title="Borrar Oferta" arrow>
                          <IconButton
                            sx={{
                              '&:hover': { background: theme.colors.error.lighter },
                              color: theme.palette.error.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => handleDeleteClickOpen(index)}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>

                        {promoOffers.map((promoOffer, index) => (
                          <Dialog
                            key={index}
                            open={openDeleteDialogs[index] || false} // Ensure boolean value, avoid `undefined`
                            onClose={() => handleDeleteClickClose(index)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Eliminar la oferta promocional??"}</DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Estás seguro que desea eliminar la oferta promocional? Este cambio no se puede deshacer.
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={() => handleDeleteClickClose(index)}>Cancelar</Button>
                              <Button onClick={() => handleDeleteOffers(promoOffer, index)} autoFocus>Eliminar</Button>
                            </DialogActions>
                          </Dialog>
                        ))}

                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 30]}
        />
      </Box>
      <PromoOfferDialog
        open={openEditOfferDialog}
        onClose={handleCloseDialog}
        promoOffer={promoOfferToEdit}
        onSuccess={handleOffersUpdate}
      />
    </Card>
  );
};

CubacelOffersTable.propTypes = {
  promoOffersList: PropTypes.array.isRequired
};

CubacelOffersTable.defaultProps = {
  promoOffersList: []
};

export default CubacelOffersTable;
