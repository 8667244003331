import React, { useState } from 'react';
import { Typography, Button, Grid, Tooltip, IconButton, DialogContent, DialogContentText } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import PromoOfferDialog from '../../Components/Modals/PromoOfferDialog';
import { UserRole } from '../../../../types/enums';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

interface PageHeaderProps {
  onSuccess?: () => void;
}

function PageHeader({ onSuccess }: PageHeaderProps) {
  const userName = localStorage.getItem('username');
  const user = {
    //name: 'Catherine Pike',
    avatar: '/static/images/avatars/1.jpg'
  };

  // State for controlling the dialog visibility
  const [openDialog, setOpenDialog] = useState(false);
  const roles = localStorage.getItem("rid");
  // Function to open the dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOffersUpdate = () => {
    onSuccess();
  };
  function getUserRole(roleId: string): UserRole {
    // Map roleId to UserRole

    const roleIdToUserRoleMap: { [key: string]: UserRole } = {
      "65f53af8d470c2149a1b35dd": UserRole.USER,
      "65f53b1cd470c2149a1b35df": UserRole.STAFF,
      "65f53b26d470c2149a1b35e1": UserRole.SUPERVISOR,
      "65f53b55d470c2149a1b35e3": UserRole.ADMIN,

    };

    // Get UserRole based on roleId
    const userRole = roleIdToUserRoleMap[roleId] || UserRole.USER;

    return userRole;
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            Ofertas
          </Typography>
          <Typography variant="subtitle2">
            {userName}, estos son las ofertas.
          </Typography>
        </Grid>
        <Grid item>
          {(getUserRole(roles) === UserRole.ADMIN) && (

            <>
              <Button
                onClick={handleOpenDialog} // Open the dialog when the button is clicked
                sx={{ mt: { xs: 2, md: 0 } }}
                variant="contained"
                startIcon={<AddTwoToneIcon fontSize="small" />}
              >
                Crear oferta
              </Button>
            </>

          )}

        </Grid>
      </Grid>
      <PromoOfferDialog
        open={openDialog} // Pass the open state to the PromoOfferDialog component
        onClose={handleCloseDialog} // Pass the function to close the dialog
        promoOffer={{}} // You can pass an initial promo offer object here if needed
        onSuccess={handleOffersUpdate}
      />
    </>
  );
}

export default PageHeader;
