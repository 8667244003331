import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Grid,
  Box, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { UserStatus } from '../../../../types/models/Users';
import UsersRepository from '../../SignIn/UsersRepository';


const statusOptions = [
  { id: 'PENDING', name: 'Pendiente' },
  { id: 'BLOCKED', name: 'Bloqueado' },
  { id: 'ACTIVE', name: 'Activo' },
  { id: 'REMOVED', name: 'Removido' }
];

const UserManagementDialog = ({ open, onClose, userData, onSave }) => {
  const defaultUserData = userData || {
    name: '',
    email: '',
    phone: '',
    agencyId: '',
    status: 'PENDING',
    emailNotificationsAllowed: false,
    smsNotificationsAllowed: false,
    emailConfirmed: false,
    phoneNumberConfirmed: false,
    storeAccess:false
  };

  const [name, setName] = useState(defaultUserData.name);
  const [email, setEmail] = useState(defaultUserData.email);
  const [phone, setPhone] = useState(defaultUserData.phone);
  const [agencyId, setAgencyId] = useState(defaultUserData.agencyId);
  const [status, setStatus] = useState<UserStatus>(defaultUserData.status);
  const [emailNotificationsAllowed, setEmailNotificationsAllowed] = useState(defaultUserData.emailNotificationsAllowed);
  const [smsNotificationsAllowed, setSmsNotificationsAllowed] = useState(defaultUserData.smsNotificationsAllowed);
  const [emailConfirmed, setEmailConfirmed] = useState(defaultUserData.emailConfirmed);
  const [phoneNumberConfirmed, setPhoneNumberConfirmed] = useState(defaultUserData.phoneNumberConfirmed);
  const [storeAccess, setStoreAccess] = useState(defaultUserData.storeAccess);

  const usersRepository = new UsersRepository();


  useEffect(() => {
    setName(defaultUserData.name);
    setEmail(defaultUserData.email);
    setPhone(defaultUserData.phone);
    setAgencyId(defaultUserData.agencyId);
    setStatus(defaultUserData.status);
    setEmailNotificationsAllowed(defaultUserData.emailNotificationsAllowed);
    setSmsNotificationsAllowed(defaultUserData.smsNotificationsAllowed);
    setEmailConfirmed(defaultUserData.emailConfirmed);
    setPhoneNumberConfirmed(defaultUserData.phoneNumberConfirmed);
    setStoreAccess(defaultUserData.storeAccess);
  }, [defaultUserData]);


  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    const updatedUserData = {
      name,
      email,
      phone,
      agencyId,
      status,
      emailNotificationsAllowed,
      smsNotificationsAllowed,
      emailConfirmed,
      phoneNumberConfirmed,
      storeAccess
    };

    console.log("updatedUserData: ",updatedUserData);
    usersRepository.updateUser(userData.id, updatedUserData).then((data) => {
      onSave(updatedUserData);
      handleClose();
    }).catch(error => {
      console.error('Error updating promo offer:', error);

    });

  };


  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edición de Usuarios</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              style={{marginTop:10}}
              label="Nombre"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Correo electrónico"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Teléfono"
              variant="outlined"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="ID de compañia"
              variant="outlined"
              fullWidth
              value={agencyId}
              onChange={(e) => setAgencyId(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Estado del usuario</InputLabel>
              <Select

                value={status}
                onChange={(e) => setStatus(e.target.value as UserStatus)}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Switch checked={emailNotificationsAllowed} onChange={() => setEmailNotificationsAllowed(!emailNotificationsAllowed)} />}
              label="Email Notifications Allowed"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Switch checked={smsNotificationsAllowed} onChange={() => setSmsNotificationsAllowed(!smsNotificationsAllowed)} />}
              label="SMS Notifications Allowed"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Switch checked={emailConfirmed} onChange={() => setEmailConfirmed(!emailConfirmed)} />}
              label="Email Confirmed"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Switch checked={phoneNumberConfirmed} onChange={() => setPhoneNumberConfirmed(!phoneNumberConfirmed)} />}
              label="Phone Number Confirmed"
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Button variant="contained" onClick={handleSave}>Save</Button>
          <Button variant="outlined" onClick={handleClose} sx={{ mx: 1 }}>Cancel</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );

};

UserManagementDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired
};

export default UserManagementDialog;
