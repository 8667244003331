import HttpService from '../../../services/api/HttpService';
import { Pageable } from '../../../types/models/Pageable';
//import { Sort } from '../../../types/models/Sort';

interface ApiResponse {
    status: number;
    data: ChatInfoData;
    error: any; // or null if the error can be null
}

export interface ChatInfoData {
    content: ChatInfo[];
    pageable: Pageable;
    last: boolean;
    totalPages: number;
    totalElements: number;
    first: boolean;
    size: number;
    number: number;
    //sort: Sort;
    numberOfElements: number;
    empty: boolean;
}

export interface ChatInfo {
    id: string;
    customerId: string;
    customerName:string;
    customerPhotoUrl : string;
    sessionId: string;
    resume: string;
    lastUpdated: string;
    conversations: Conversation[];
}

export interface Conversation {
    id: string;
    chatId: string;
    startTime: string;
    status: string; // consider using an enum for statuses
    messages: Message[];
}

export interface Message {
    id: string;
    author: Author;
    chatId: string;
    senderId: string;
    messageText: string;
    partialText: PartialText;
    type: string; // consider using an enum for message types
    timestamp: number;
}

export interface Author {
    id: string;
    firstName: string;
}

export interface PartialText {
    metadata: string;
    text: string;
}

export interface ChatUser {
    customerName:string;
    customerPhotoUrl : string;
    lastUpdated: string;
}

//
// interface Sort {
//     empty: boolean;
//     sorted: boolean;
//     unsorted: boolean;
// }

class MessengerRepository {
    private httpService: HttpService;

    constructor() {
        this.httpService = new HttpService();
        this.httpService.service();
    }

    async fetchChats(
      page: number = 0,
      pageSize: number = 20,
      filter: string = ''
    ): Promise<ChatInfoData> {
        try {
            // Correctly type the response as ApiResponse
            const response = await this.httpService.get<ApiResponse>(`/chats?page=${page}&pageSize=${pageSize}&filter=${filter}`);

            // Extract ChatInfoData from the ApiResponse
            const chatInfoData = response.data;

            //console.log('Response:', response);
            //console.log('ChatInfoData:', chatInfoData);

            return chatInfoData;
        } catch (error) {
            console.error('Failed to fetch chats:', error);
            throw new Error('Failed to fetch chats');
        }
    }
}

export default MessengerRepository;
