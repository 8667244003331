import * as React from 'react';
import Avatar from '@mui/material/Avatar';
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { setToken, setUser } from "../../types/actions";
import { DecodedUser } from "../../../types/models/DecodedUser";
import { jwtDecode } from "jwt-decode";
import { User } from "../../../types/models/User";
import UsersRepository from "./UsersRepository";
import { useNavigate } from "react-router-dom";
import * as CryptoJS from 'crypto-js';
import { Google as GoogleIcon, Facebook as FacebookIcon } from '@mui/icons-material/';
import { getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, signInWithEmailAndPassword   } from "firebase/auth";
import Button from '@mui/material/Button'; // Importing Button component from Material-UI
import {Card, CardContent, InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";
//import { authenticate  } from '../../../hooks/useAuth';
import { useAuth } from '../../../hooks/useAuth';
import { getRoleLabelFromRoleId } from '../../../utils/utils';




interface SignInProps {}

function SignIn(props: SignInProps): JSX.Element {
    const { authenticate } = useAuth();

    const signInScreenRepository = new UsersRepository();
    const navigate = useNavigate();
    //const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const showErrorMessage = (message: string): void => {
      //  toast.error(message);
    };
    const showSuccessMessage = (message: string): void => {
        //toast.success(message);
    };

    function decryptString(encryptedString: string, secretKey: string): string {
        const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);

        return originalText;
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');
        const password = data.get('password');


        if (!email || !password) {
            showErrorMessage('Debes llenar todos los campos');
            return;
        }
        if (!isValidEmail(email?.toString())) {
            showErrorMessage('Correo electrónico no válido');
            return;
        }

        try {
            const response = await signInScreenRepository.authenticateUser(email.toString(), password.toString());
            console.log("response.data?.token", response.data);
            if (response.status === 200){
                if (response.data?.token != null){
                    const token: string | null = response.data?.token;
                    if (token != null) {
                        const decodedUser: DecodedUser = jwtDecode<DecodedUser>(token);
                        const reduxUser: User = transformToReduxUser(decodedUser);


                        localStorage.setItem("username", reduxUser.name);
                        localStorage.setItem("email", reduxUser.email);
                        localStorage.setItem("id", reduxUser.id);
                        localStorage.setItem("token", token);
                        localStorage.setItem("photoURL", reduxUser.photoURL);
                        localStorage.setItem("rid", reduxUser.rid);
                        localStorage.setItem("role", getRoleLabelFromRoleId(localStorage.getItem("rid")));

                        console.log("rig inside", localStorage.getItem("rid") )
                        authenticate(reduxUser.id);
                        navigate('/principal');
                    }
                }else{
                    showErrorMessage('Ocurrió un error');
                }

            }else{

                if (response.error != null) {
                   // showErrorMessage(`Ha ocurrido un error: ${response.error}`);
                } else {
                    // showErrorMessage('Ha ocurrido un error desconocido');
                }
            }

        } catch (error) {
            console.error('Ocurrió un error: fuera', error);
            const errorMessage = (error as Error).message || 'Ocurrió un error desconocido.';
            if (errorMessage.includes('Request failed with status code 401')) {
                // showErrorMessage('Nombre de usuario o contraseña incorrectos');
            } else {
                // showErrorMessage(errorMessage);
            }
        }finally {
            //   setLoading(false);
        }



    };

    const handleFirebaseServerSignIn = async (
      name: string,
      email: string,
      phone: string,
      photoURL: string,
      uid: string,
      idToken: string,
      provider: string,
      emailVerified: boolean,
    ) => {
        try {
            const response = await signInScreenRepository.authenticateFirebaseUser(name, email, phone, photoURL, uid, idToken, provider, emailVerified);
            console.log("response.data?.token", response.data);
            if (response.status === 200){
                if (response.data?.token != null){
                    const token: string | null = response.data?.token;
                    if (token != null) {
                        const decodedUser: DecodedUser = jwtDecode<DecodedUser>(token);
                        const reduxUser: User = transformToReduxUser(decodedUser);

                        localStorage.setItem("username", reduxUser.name);
                        localStorage.setItem("email", reduxUser.email);
                        localStorage.setItem("id", reduxUser.id);
                        localStorage.setItem("token", token);
                        localStorage.setItem("rid", reduxUser.rid);
                        localStorage.setItem("photoURL", reduxUser.photoURL);
                        localStorage.setItem("role", getRoleLabelFromRoleId(localStorage.getItem("rid")));

                        console.log("rig inside", localStorage.getItem("rid") )
                        authenticate(reduxUser.id);
                        navigate('/principal');
                       
                    }
                }else{
                    showErrorMessage('Ocurrió un error');
                }

            }else{

                if (response.error != null) {
                    // showErrorMessage(`Ha ocurrido un error: ${response.error}`);
                } else {
                    // showErrorMessage('Ha ocurrido un error desconocido');
                }
            }

        } catch (error) {
            console.error('Ocurrió un error: fuera', error);
            const errorMessage = (error as Error).message || 'Ocurrió un error desconocido.';
            if (errorMessage.includes('Request failed with status code 401')) {
                // showErrorMessage('Nombre de usuario o contraseña incorrectos');
            } else {
                // showErrorMessage(errorMessage);
            }
        }finally {
            //   setLoading(false);
        }
    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const isValidEmail = (email: string | undefined): boolean => {
        // Implement your email validation logic here
        // You can use regular expressions or any other method to validate the email format
        return true; // Placeholder return value
    };

    const transformToReduxUser = (decodedUser: DecodedUser): User => {
        const id = decodedUser.sub.split('~')[1]; // Split the email string by '~' and get the portion after '~'
        const reduxUser: User = {
            id: id,
            name: decodedUser.name,
            email: decodedUser.email,
            photoURL : decodedUser.photoURL,
            rid: decodedUser.rid,
        };
        return reduxUser;
    };

    const defaultTheme = createTheme();

    ///const auth = getAuth();
    const handleFacebookLogin = async () => {
        try {
            // Create Facebook auth provider instance
            const auth = getAuth();
            const provider = new FacebookAuthProvider();

            // Set additional scopes to request email and phone
            provider.addScope('email');
            //provider.addScope('phone');

            // Sign in with Facebook using popup
            const result = await signInWithPopup(auth, provider);

            // Handle successful sign-in
            const user = result.user;
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential?.accessToken;
            console.log('Facebook Access Token:', accessToken);
            console.log('Facebook User:', user);
            await handleFirebaseServerSignIn(user.displayName, user.email, "", user.photoURL, user.uid, await user.getIdToken(), "FACEBOOK", user.emailVerified);
        } catch (error) {
            // Handle errors
            console.error('Error signing in with Facebook:', error);
        }
    };

    const handleGoogleLogin = async () => {
        const auth = getAuth();
        const googleProvider = new GoogleAuthProvider();
        try {

            const result = await signInWithPopup(auth, googleProvider);

            console.log('Successfully signed in with Google:', result.user);

            const user = result.user;
            await handleFirebaseServerSignIn(user.displayName, user.email, "", user.photoURL, user.uid, await user.getIdToken(), "GOOGLE", user.emailVerified);
        } catch (error) {
            // Handle errors
            console.error('Error signing in with Google:', error);
        }
    };

    // @ts-ignore
    return (
        <Box>
            <Box component="main" sx={{ height: '92vh', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                <Card
                    variant="outlined"
                    sx={{
                        maxWidth: 460,
                        borderRadius: 4,
                        height: '95%',
                        margin: 'auto',
                        padding: '5px',

                    }}>
                    <CardContent>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                            <img
                                src="/logo.png"
                                alt="Icon"
                                style={{
                                    borderRadius: '50%',
                                    width: '80px',
                                    height: '80px',
                                    marginRight: 12
                                }}
                            />


                            <Typography component="h1" variant="h5">
                                Iniciar sesión
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                                <TextField
                                    size="small"
                                    sx={{
                                        '& input': { fontSize: '12px' }, // Adjust input text size here
                                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Adjust label text size here
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo Electrónico"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    size="small"
                                    sx={{
                                        '& input': { fontSize: '12px' }, // Adjust input text size here
                                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Adjust label text size here
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleTogglePasswordVisibility}
                                                >
                                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary"/>}
                                    label={<Typography variant="body2" sx={{ fontSize: 12 }}>Guardar Contraseña</Typography>}
                                />
                                <Grid container justifyContent={'center'}>
                                

                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        className="signin"
                                        sx={{ maxWidth: 300, maxHeight: 60, marginTop: 1, fontSize: 12, backgroundColor: '#d52b1e',color: 'white', }}
                                    >
                                        Iniciar sesión
                                    </Button>


                                    <Button
                                        fullWidth
                                        size="large"
                                        className="googleButton"
                                        sx={{ maxWidth: 300, maxHeight: 60, marginTop: 3, fontSize: 12, backgroundColor: '#005baa',color: 'white', }}
                                        startIcon={<img src="/static/images/logo/google-1.svg" alt="google" />} 
                                        onClick={handleGoogleLogin}
                                    >
                                        Continuar con Google
                                    </Button>

                                    <Button
                                        fullWidth
                                        size="large"
                                        className="facebookButton"
                                        sx={{ maxWidth: 300, maxHeight: 60, marginTop: 1, fontSize: 12, backgroundColor: 'rgb(59, 89, 152)',color: 'white', }}
                                        startIcon={<img src="/static/images/logo/facebook-filled-white.svg" alt="facebook" />}
                                        onClick={handleFacebookLogin}
                                    >
                                        Continuar con Facebook
                                    </Button>
                                    
                                    </Grid>
                                <div className="g-signin2" data-onsuccess="onSignIn"></div>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
}

export default SignIn;


/*





*/




/*

<Box>
    <ResponsiveAppBar />
    <Box component="main" sx={{ height: '92vh', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>

        <Card
            variant="outlined"
            sx={{
                maxWidth: 460,
                borderRadius: 4,
                height: '95%',
                margin: 'auto',
                padding: '5px',

            }}
            container
        >
            <CardContent>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src="/logo.png"
                        alt="Icon"
                        style={{
                            borderRadius: '50%',
                            width: '80px',
                            height: '80px',
                            marginRight: 12
                        }}
                    />


                </Box>
            </CardContent>
        </Card>
    </Box>
</Box>



*/










