import { Typography, Avatar, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

function PageHeader() {
  const userName = localStorage.getItem("username");
  const [quote, setQuote] = useState('');
  const [author, setAuthor] = useState('');
  const user = {
    //name: 'Catherine Pike',
    avatar: localStorage.getItem("photoURL"),
  };
  const theme = useTheme();


  useEffect(() => {
    getQuote()
  }, []);

  async function getQuote() {
    const url = 'https://quotes15.p.rapidapi.com/quotes/random/?language_code=es';
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': 'ab24e9223emsh9575d1367cd8a53p1ac7abjsn8429fd70107b',
        'X-RapidAPI-Host': 'quotes15.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json(); // Parse JSON response

      // Extract name and content from data
      const { name } = data.originator;
      const { content } = data;

        setQuote(content);
      setAuthor(name);
      // Create and return Quote object
      const Quote = { name, content };
      console.log(Quote);
      return Quote;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  return (
    <Grid container alignItems="center">
      <Grid item>
        <Avatar
          sx={{
            mr: 2,
            width: theme.spacing(8),
            height: theme.spacing(8)
          }}
          variant="rounded"
          alt={userName}
          src={user.avatar}
        />
      </Grid>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Bienvenido, {userName}!
        </Typography>
        <Typography variant="subtitle2" style={{ visibility: quote ? 'visible' : 'hidden' }}>
          {"\"" + quote + "\""}
        </Typography>

        <Typography variant="subtitle2" style={{ textAlign: 'right', fontWeight: 'bold' }}>
          {author}
        </Typography>

      </Grid>
    </Grid>
  );
}

export default PageHeader;
