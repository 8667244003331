import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  Avatar,
  useTheme,
  styled
} from '@mui/material';

import ShoppingBagTwoToneIcon from '@mui/icons-material/ShoppingBagTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import { RecentActivityData } from '../../../../types/models/UserProfile';
// Remove this import if it's not needed
// import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';

const AvatarPrimary = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
`
);

interface RecentActivityProps {
  recentActivity?: RecentActivityData;
}

// // Assuming RecentActivity is defined elsewhere
// interface RecentActivity {
//   ordersTotal: number;
//   ordersFailed: number;
//   favouritesProducts: number;
//   favouritesLists: number;
// }

function RecentActivity({ recentActivity }: RecentActivityProps) {
  const theme = useTheme();

  if (!recentActivity) {
    return null; // Or handle the absence of data as required
  }

  return (
    <Card>
      <CardHeader title="Recent Activity" />
      <Divider />
      <Box px={2} py={4} display="flex" alignItems="flex-start">
        <AvatarPrimary>
          <ShoppingBagTwoToneIcon />
        </AvatarPrimary>
        <Box pl={2} flex={1}>
          <Typography variant="h3">Orders</Typography>

          <Box pt={2} display="flex">
            <Box pr={8}>
              <Typography
                gutterBottom
                variant="caption"
                sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
              >
                Total
              </Typography>
              <Typography variant="h2">{recentActivity.successfulTransactions}</Typography>
            </Box>
            <Box>
              <Typography
                gutterBottom
                variant="caption"
                sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
              >
                Failed
              </Typography>
              <Typography variant="h2">{recentActivity.failedTransactions}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box px={2} py={4} display="flex" alignItems="flex-start">
        <AvatarPrimary>
          <FavoriteTwoToneIcon />
        </AvatarPrimary>
        <Box pl={2} flex={1}>
          <Typography variant="h3">Favourites</Typography>

          <Box pt={2} display="flex">
            <Box pr={8}>
              <Typography
                gutterBottom
                variant="caption"
                sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
              >
                Products
              </Typography>
              <Typography variant="h2">{recentActivity.cartItemsQuantity}</Typography>
            </Box>
            <Box>
              <Typography
                gutterBottom
                variant="caption"
                sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
              >
                Lists
              </Typography>
              <Typography variant="h2">{recentActivity.cartItemsQuantity}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      {/* Uncomment and use if needed */}
      {/*<Box px={2} py={4} display="flex" alignItems="flex-start">*/}
      {/*  <AvatarPrimary>*/}
      {/*    <StarTwoToneIcon />*/}
      {/*  </AvatarPrimary>*/}
      {/*  <Box pl={2} flex={1}>*/}
      {/*    <Typography variant="h3">Reviews</Typography>*/}

      {/*    <Box pt={2} display="flex">*/}
      {/*      <Box pr={8}>*/}
      {/*        <Typography*/}
      {/*          gutterBottom*/}
      {/*          variant="caption"*/}
      {/*          sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}*/}
      {/*        >*/}
      {/*          Total*/}
      {/*        </Typography>*/}
      {/*        <Typography variant="h2">654</Typography>*/}
      {/*      </Box>*/}
      {/*      <Box>*/}
      {/*        <Typography*/}
      {/*          gutterBottom*/}
      {/*          variant="caption"*/}
      {/*          sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}*/}
      {/*        >*/}
      {/*          Useful*/}
      {/*        </Typography>*/}
      {/*        <Typography variant="h2">21</Typography>*/}
      {/*      </Box>*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
    </Card>
  );
}

export default RecentActivity;
