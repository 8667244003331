import React from 'react';
import { Grid, Typography } from '@mui/material';
import ProductCard from './ProductCard';
import DingConnectRepository from './DingConnectRepository';
import { FullDingProduct } from '../../types/models/FullDingProduct';

const ProductCards: React.FC = () => {
  const [fullDingProduct, setFullDingProduct] = React.useState<FullDingProduct[]>([]);
  const dingConnectRepository = new DingConnectRepository();

  React.useEffect(() => {
    dingConnectRepository.fetchFullDingProducts().then(fullDingProductsResponse =>{
      console.log('fullDingPromotionsResponse', fullDingProductsResponse)
      setFullDingProduct(fullDingProductsResponse)
    }).catch((error) => {
      console.error('Failed to fetch Account Balance:', error);
    });
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Productos
      </Typography>
      <Grid container spacing={2}>
        {fullDingProduct.map((product, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ProductCard dingProduct={product} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProductCards;


