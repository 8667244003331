// models/Promo.ts
import { FullDingPromotion, PromotionDescriptionItem, PromotionPeriod } from './FullDingPromotion';
import { FullDingProduct } from './FullDingProduct';
import { formatDate } from '../../utils/utils';

export type PromoOfferStatus = 'COMING' | 'EXPIRED' | 'DEACTIVATED' | 'ACTIVE';
export type PromoOfferVariant = 'PRODUCT' | 'PROMOTION' ;


export class PromoOffer {
    id: string;

    name: string;

    description: string;

    userId: string;

    validity: number;

    bonus: string;

    price: number;

    vipPrice: number;

    costPrice: number;

    revPercent: number;

    oldPrice: number;


    type: string;

    status: PromoOfferStatus;

    variant : PromoOfferVariant;

    comments: string;

    infoLabel: InfoLabel;

    startDate: number;

    endingDate: number;

    picture :string

    productFullDescription : FullDingProduct | null;

    dingPromotionFullDescription : FullDingPromotion | null;

    createdAt: Date;

    constructor(
        id: string,
        name: string,
        description: string,
        userId: string,
        validity: number,
        bonus: string,
        price: number,
        vipPrice: number,
        costPrice: number,
        revPercent: number,
        oldPrice: number,
        type: string,
        status: PromoOfferStatus,
        variant:PromoOfferVariant,
        comments: string,
        infoLabel: InfoLabel,
        startDate: number,
        endingDate: number,
        picture: string,
        productFullDescription : FullDingProduct | null,
        dingPromotionFullDescription : FullDingPromotion | null,
        createdAt: number

    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.userId = userId;
        this.validity = validity;
        this.bonus = bonus;
        this.price = price;
        this.vipPrice = vipPrice;
        this.costPrice = costPrice;
        this.revPercent = revPercent;
        this.oldPrice = oldPrice;
        this.type = type;
        this.status = status;
        this.variant = variant;
        this.comments = comments;
        this.infoLabel = infoLabel;
        this.startDate = startDate;
        this.endingDate = endingDate;
        this.picture = picture;
        this.productFullDescription = productFullDescription ?? null;
        this.dingPromotionFullDescription = dingPromotionFullDescription ?? null;
        this.createdAt = new Date(createdAt);
    }
}


export interface InfoLabel {
    text: string;
    color: string;
}