import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';

import NetworkCallsTablesDisplay from './NetworkCallsTablesDisplay';

function NetworkCalls() {
  return (
    <>
      <Helmet>
        <title>Network Calls - Applications</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}>
          <Grid item xs={12}>
            <NetworkCallsTablesDisplay />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default NetworkCalls;
