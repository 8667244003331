import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader
} from '@mui/material';

import Label from 'src/components/Label';
import { Transaction, TransactionOrderStatus } from 'src/models/transaction_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BulkActions from './BulkActions';
import { formatDate, formatDateFromTimeStamp } from 'src/utils/utils';
import TransactionsRepository from './TransactionsRepository';

interface RecentOrdersTableProps {
  className?: string;
}

interface Filters {
  status?: TransactionOrderStatus;
}

const getStatusLabel = (transactionOrderStatus: TransactionOrderStatus): JSX.Element => {
  const map = {
    IN_PROCESS: {
      text: 'En Proceso',
      color: 'warning' // Red color for failed status
    },
    FAILED: {
      text: 'Fallida',
      color: 'error' // Red color for failed status
    },
    COMPLETED: {
      text: 'Completada',
      color: 'success' // Green color for completed status
    },
    PENDING: {
      text: 'Pendiente',
      color: 'warning' // Yellow color for pending status
    },
    CANCELLED: {
      text: 'Cancelada',
      color: 'info' // Blue color for cancelled status
    },
    REFUNDED: {
      text: 'Reembolsada',
      color: 'info' // Blue color for refunded status
    },
    DISPUTED: {
      text: 'En disputa',
      color: 'info' // Blue color for disputed status
    }
};



  const { text, color }: any = map[transactionOrderStatus];

  return <Label color={color}>{text}</Label>;
};

const applyFilters = (
  transactionOrders: Transaction[],
  filters: Filters
): Transaction[] => {
  return transactionOrders.filter((transaction) => {
    let matches = true;

    /* if (filters.status && transaction.status !== filters.status) {
      matches = false;
    } */

    return matches;
  });
};

const RecentOrdersTable: FC<RecentOrdersTableProps> = ({ }) => {
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedTransactions.length > 0;
  const [filters, setFilters] = useState<Filters>({
    status: null
  });
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);
  const transactionsRepository = new TransactionsRepository();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);


  useEffect(() => {
    transactionsRepository.fetchTransactions(page, limit).then((data) => {
      setTransactions(data.data)
      setTotalItems(data.totalElements);
    }).catch((error) => {
      console.error('Failed to fetch transactions:', error);
    });

  }, [page, limit]);

  const statusOptions = [
    {
      id: 'all',
      name: 'Todas'
    },
    {
      id: 'PENDING',
      name: 'Pendiente'
    },
    {
      id: 'COMPLETED',
      name: 'Completada'
    },
    {
      id: 'CANCELLED',
      name: 'Cancelada'
    },
    {
      id: 'REFUNDED',
      name: 'Reembolsada'
    },
    {
      id: 'DISPUTED',
      name: 'En Disputa'
    },
    {
      id: 'FAILED',
      name: 'Fallida'
    }
  ];


  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

 

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredTransactions = applyFilters(transactions, filters);

  const theme = useTheme();

  return (
    <Card>
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          title="Transacciones recientes"
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order Details</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>Source</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTransactions.map((transaction) => {
             /*  const isCryptoOrderSelected = selectedCryptoOrders.includes(
                cryptoOrder.id
              ); */
              return (
                <TableRow
                  hover
                  key={transaction.id}
                  /* selected={isCryptoOrderSelected} */
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isCryptoOrderSelected}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleSelectOneCryptoOrder(event, cryptoOrder.id)
                      }
                      value={isCryptoOrderSelected}
                    />
                  </TableCell> */}
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.serviceType}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {formatDateFromTimeStamp(transaction.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.contactName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {transaction.cubAccount}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {transaction.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {numeral(transaction.price).format(
                        `${'$'}0,0.00`
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {getStatusLabel(transaction.status)}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <EditTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={totalItems}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

// RecentOrdersTable.propTypes = {
//   transactionOrders: PropTypes.array.isRequired
// };
//
// RecentOrdersTable.defaultProps = {
//   transactionOrders: []
// };

export default RecentOrdersTable;
