

export enum EHttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}


export enum PaymentMethodtype {
    CARD = "CARD",
    PAYPAL = "PAYPAL",
    GOOGLEPAY = "GOOGLEPAY",
    APPLEPAY = "APPLEPAY",
}

export enum UserRole {
    USER = "USER",
    STAFF = "STAFF",
    SUPERVISOR = "SUPERVISOR",
    ADMIN = "ADMIN",
}
