import {
  Button,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Avatar,
  alpha,
  Tooltip,
  CardActionArea,
  styled
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useEffect, useState } from 'react';

const AvatarWrapperError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color:  ${theme.colors.error.main};
`
);

const AvatarWrapperSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color:  ${theme.colors.success.main};
`
);

const AvatarWrapperWarning = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.warning.lighter};
      color:  ${theme.colors.warning.main};
`
);

const AvatarWrapperHealth = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color:  ${theme.colors.primary.main};
`
);



function GenStats() {

  const [activeUsers, setActiveUsers] = useState('0');
  const [transactionNumber, setTransactionNumber] = useState('0');
  const [totalSales, setTotalSales] = useState('$0.00');
  const [totalRevenues, setTotalRevenues] = useState('$0.00');

  useEffect(() => {
    const url = new URL(`${process.env.REACT_APP_WEBSOCKET_URL}/ws/activeUsers`);
    const socket = new WebSocket(url.toString());
    socket.onopen = () => {
      socket.send('Hello from client!');
    };
    socket.onmessage = (event) => {
      setActiveUsers(event.data);
    };
    socket.onclose = () => {
    };
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const url = new URL(`${process.env.REACT_APP_WEBSOCKET_URL}/ws/transactionNumber`);
    const socket = new WebSocket(url.toString());
    socket.onopen = () => {
      socket.send('Hello from client!');
    };
    socket.onmessage = (event) => {
      setTransactionNumber(event.data);
    };
    socket.onclose = () => {
    };
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const url = new URL(`${process.env.REACT_APP_WEBSOCKET_URL}/ws/totalSales`);
    const socket = new WebSocket(url.toString());
    socket.onopen = () => {
      socket.send('Hello from client!');
    };
    socket.onmessage = (event) => {
      setTotalSales(event.data);
    };
    socket.onclose = () => {
    };
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const url = new URL(`${process.env.REACT_APP_WEBSOCKET_URL}/ws/totalRevenues`);
    const socket = new WebSocket(url.toString());
    socket.onopen = () => {
      socket.send('Hello from client!');
    };
    socket.onmessage = (event) => {
      setTotalRevenues(event.data);
    };
    socket.onclose = () => {
    };
    return () => {
      socket.close();
    };
  }, []);
  return (
    <>
      {/*<Box*/}
      {/*  display="flex"*/}
      {/*  alignItems="normal"*/}
      {/*  justifyContent="space-between"*/}
      {/*  sx={{*/}
      {/*    pb: 3,*/}
      {/*    width: '100%
      {/*  }}*/}
      {/*>*/}
      {/*  <Typography variant="h3">Wallets</Typography>*/}
      {/*</Box>*/}

      <Grid container spacing={3}>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1, py: 1
            }}
          >
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
              >
                <AvatarWrapperWarning>
                  <PeopleAltIcon />
                </AvatarWrapperWarning>
                <Typography sx={{marginLeft: 1}} variant="h5" noWrap>
                  Usuarios
                </Typography>
              </Box>

              <Box sx={{ textAlign: 'center' , marginTop: 1}}>
                <Typography variant="h1" gutterBottom noWrap>
                  {activeUsers}
                </Typography>
                <Typography sx={{ marginTop: 1, marginBottom:1.3}} variant="body1" noWrap>
                  últimas 24 horas
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1, py: 1
            }}
          >
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
              >
                <AvatarWrapperHealth>
                  <ReceiptIcon />
                </AvatarWrapperHealth>
                <Typography sx={{marginLeft: 1}} variant="h5" noWrap>
                  Órdenes
                </Typography>
              </Box>

              {/*<Typography variant="h5" noWrap>*/}
              {/*  Bitcoin*/}
              {/*</Typography>*/}
              {/*<Typography variant="subtitle1" noWrap>*/}
              {/*  BTC*/}
              {/*</Typography>*/}
              <Box sx={{ textAlign: 'center' , marginTop: 1}}>
                <Typography variant="h1" gutterBottom noWrap>
                  {transactionNumber}
                </Typography>
                <Typography sx={{ marginTop: 1, marginBottom:1.3}} variant="body1" noWrap>
                  Desde la última promoción
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1, py: 1
            }}
          >
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
              >
                <AvatarWrapperSuccess>
                  <PointOfSaleIcon />
                </AvatarWrapperSuccess>
                <Typography sx={{marginLeft: 1}} variant="h5" noWrap>
                  Ventas
                </Typography>
              </Box>

              {/*<Typography variant="h5" noWrap>*/}
              {/*  Bitcoin*/}
              {/*</Typography>*/}
              {/*<Typography variant="subtitle1" noWrap>*/}
              {/*  BTC*/}
              {/*</Typography>*/}
              <Box sx={{ textAlign: 'center' , marginTop: 1}}>
                <Typography variant="h1" gutterBottom noWrap>
                  {totalSales}
                </Typography>
                <Typography sx={{ marginTop: 1, marginBottom:1.3}} variant="body1" noWrap>
                  -15.35% menos que el mes anterior
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1, py: 1
            }}
          >
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
              >
                <AvatarWrapperError>
                  <AttachMoneyIcon />
                </AvatarWrapperError>
                <Typography sx={{marginLeft: 1}} variant="h5" noWrap>
                  Revenues
                </Typography>
              </Box>

              {/*<Typography variant="h5" noWrap>*/}
              {/*  Bitcoin*/}
              {/*</Typography>*/}
              {/*<Typography variant="subtitle1" noWrap>*/}
              {/*  BTC*/}
              {/*</Typography>*/}
              <Box sx={{ textAlign: 'center' , marginTop: 1}}>
                <Typography variant="h1" gutterBottom noWrap>
                  {totalRevenues}
                </Typography>
                <Typography sx={{ marginTop: 1, marginBottom:1.3}} variant="body1" noWrap>
                  por el promedio del mes anterior
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </>
  );
}

export default GenStats;
