import React, { useState } from 'react';
import { Paper, Avatar, Typography, Divider, Tooltip, styled, IconButton, lighten } from '@mui/material';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { FullDingPromotion } from '../../types/models/FullDingPromotion';
import { formatDate, formatUSD, getUserRole } from '../../utils/utils';
import AddIcon from '@mui/icons-material/Add';
import { PromoOffer } from '../../types/models/PromoOffer';
import { UserRole } from '../../types/enums';
import PromoOfferDialog from '../pages/Components/Modals/PromoOfferDialog';

interface PromotionCardProps {
  promotion: FullDingPromotion;
}

const IconButtonError = styled(IconButton)(
  ({ theme }) => `
     background: ${theme.colors.success.lighter};
     color: ${theme.colors.success.main};
     padding: ${theme.spacing(0.5)};

     &:hover {
      background: ${lighten(theme.colors.success.lighter, 0.4)};
     }
`
);

const PromotionCard: React.FC<PromotionCardProps> = ({ promotion }) => {
  const { promotionPeriod, promotionDescriptionItem } = promotion;
  const [openEditOfferDialog, setOpenEditOfferDialog] = useState(false);
  const [promoOfferToEdit, setPromoOfferToEdit] = useState(null);
  const roles = localStorage.getItem("rid");

  // Replacing new line characters with <br /> for proper rendering
  const formattedTermsAndConditions = promotionDescriptionItem.termsAndConditionsMarkDown
    .split('\n')
    .map((term) => term.trim())
    .join('<br />');

  // Function to open the dialog for editing a promo offer
  const handleSavePromotion = () => {
    const promoOffer = new PromoOffer(
      null, //dingProduct.product.localizationKey, // id
      promotionDescriptionItem.headline, // name
      getBonuses(), // description
      '', // userId
      promotionDescriptionItem.sendAmounts[0].bonuses[0].validity, // validity (in days)
      '',
      promotionPeriod.minimumSendAmount, // price
      promotionPeriod.minimumSendAmount, // price
      promotionPeriod.minimumSendAmount, // price
      0.7,
      null, // oldPrice
      '', // type

      'COMING', // status: PromoOfferStatus
      'PROMOTION',
      '', // comments
      null,
      Date.now(), // startDate as a timestamp
      Date.now() + 30 * 24 * 60 * 60 * 1000, // endingDate, 30 days from now
      "",
      null, // productFullDescription
      promotion, // dingPromotionFullDescription
      Date.now() // createdAt as a timestamp
    );

    setPromoOfferToEdit(promoOffer);
    setOpenEditOfferDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenEditOfferDialog(false);
    setPromoOfferToEdit(null);
  };


  const handleOffersUpdate = () => {


    // const fetchPromoOffers = async () => {
    //   offersRepository.fetchPromos().then((data) => {
    //     setPromoOffers(data.data)
    //
    //   }).catch((error) => {
    //     console.error('Failed to fetch offers:', error);
    //   });
    // };
    //
    // fetchPromoOffers();

  };


  const getBonuses = (): string => {
    return promotionDescriptionItem.sendAmounts
      .flatMap(sendAmount => sendAmount.bonuses.map(bonus => `${bonus.quantity} ${bonus.bonusType}`))
      .join(' + ');
  };

  return (
    <Paper elevation={1} style={{ margin: '10px', padding: '10px', position: 'relative' }}>
      <Avatar>
        <AssessmentTwoToneIcon />
      </Avatar>
      <div style={{ marginLeft: '10px' }}>
        <Typography variant="h6">{promotionDescriptionItem.headline}</Typography>
        <Typography variant="h6">Detalles de la promoción</Typography>

        <Typography>Fecha de inicio: {formatDate(promotionPeriod.startUtc)}</Typography>
        <Typography>Fecha de cierre: {formatDate(promotionPeriod.endUtc)}</Typography>
        <Typography>Recarga mínima: {formatUSD(promotionPeriod.minimumSendAmount)} USD</Typography>
        <Typography>Válida por: {promotionDescriptionItem.bonusValidity}</Typography>

        <Typography>Bonos: {getBonuses}</Typography>
      </div>

      {(getUserRole(roles) === UserRole.ADMIN) && (
        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <Tooltip arrow title="Guardar Promoción">
            <IconButtonError onClick={handleSavePromotion}>
              <AddIcon fontSize="small" />
            </IconButtonError>
          </Tooltip>
        </div>
      )}

      <Divider style={{ margin: '10px 0' }} />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Términos y condiciones</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography dangerouslySetInnerHTML={{ __html: formattedTermsAndConditions }} />
        </AccordionDetails>
      </Accordion>

      <PromoOfferDialog
        open={openEditOfferDialog}
        onClose={handleCloseDialog}
        promoOffer={promoOfferToEdit}
        onSuccess={handleOffersUpdate}
      />
    </Paper>
  );

};

export default PromotionCard;
