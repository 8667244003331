import React, { useEffect, useRef } from 'react';
import { Box, Avatar, Typography, Card, styled, Divider } from '@mui/material';
import { formatDistance, format } from 'date-fns';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
    .MuiDivider-wrapper {
      border-radius: ${theme.general.borderRadiusSm};
      text-transform: none;
      background: ${theme.palette.background.default};
      font-size: ${theme.typography.pxToRem(13)};
      color: ${theme.colors.alpha.black[50]};
    }
  `
);

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
    background: rgb(242, 245, 249);
    background: ${theme.colors.primary.main};
    color: ${theme.palette.primary.contrastText};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.general.borderRadiusXl};
    border-top-right-radius: ${theme.general.borderRadius};
    max-width: 380px;
    display: inline-flex;
  `
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[10]};
    color: ${theme.colors.alpha.black[100]};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.general.borderRadiusXl};
    border-top-left-radius: ${theme.general.borderRadius};
    max-width: 380px;
    display: inline-flex;
  `
);

const ChatContent = ({ chat }) => {
  const endOfMessagesRef = useRef(null); // Ref to scroll to the bottom

  useEffect(() => {
    // Scroll to the bottom of the chat container when the component mounts or updates
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chat]); // Depend on chat to trigger when new messages are added

  if (!chat || !Array.isArray(chat.conversations) || chat.conversations.length === 0) {
    return <Box p={3}>Select a chat to view details.</Box>;
  }

  const conversations = chat.conversations;

  return (
    <Box p={3} sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
      {conversations.map((conv, index) => {
        const { messages, date } = conv;
        const formattedDate = date ? format(new Date(date), 'MMMM dd yyyy') : 'No date available';

        return (
          <Box key={index}>
            <DividerWrapper>{formattedDate}</DividerWrapper>
            {messages.map((message, msgIndex) => {
              const isOwnMessage = message.senderId !== chat.customerId;
              const CardWrapper = isOwnMessage ? CardWrapperPrimary : CardWrapperSecondary;
              const avatarSrc = isOwnMessage ? chat.userAvatar : chat.customerPhotoUrl;
              const messageDate = new Date(message.timestamp);

              return (
                <Box
                  key={msgIndex}
                  display="flex"
                  alignItems="flex-start"
                  justifyContent={isOwnMessage ? 'flex-end' : 'flex-start'}
                  py={3}
                >
                  {!isOwnMessage && (
                    <Avatar
                      variant="rounded"
                      sx={{
                        width: 50,
                        height: 50
                      }}
                      alt={chat.customerName}
                      src={avatarSrc}
                    />
                  )}
                  <Box
                    display="flex"
                    alignItems={isOwnMessage ? 'flex-end' : 'flex-start'}
                    flexDirection="column"
                    justifyContent={isOwnMessage ? 'flex-end' : 'flex-start'}
                    mr={isOwnMessage ? 2 : 0}
                    ml={isOwnMessage ? 0 : 2}
                  >
                    <CardWrapper>
                      {message.messageText}
                    </CardWrapper>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        pt: 1,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <ScheduleTwoToneIcon
                        sx={{
                          mr: 0.5
                        }}
                        fontSize="small"
                      />
                      {formatDistance(messageDate, new Date(), {
                        addSuffix: true
                      })}
                    </Typography>
                  </Box>

                  {/*{isOwnMessage && (*/}
                  {/*  <Avatar*/}
                  {/*    variant="rounded"*/}
                  {/*    sx={{*/}
                  {/*      width: 50,*/}
                  {/*      height: 50*/}
                  {/*    }}*/}
                  {/*    alt={chat.customerName}*/}
                  {/*    src={avatarSrc}*/}
                  {/*  />*/}
                  {/*)}*/}
                </Box>
              );
            })}
          </Box>
        );
      })}
      <div ref={endOfMessagesRef} /> {/* This empty div is used to scroll to the bottom */}
    </Box>
  );
};

export default ChatContent;
