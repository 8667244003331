import HttpService from '../../../services/api/HttpService';
import { Users, UserStatus } from '../../../types/models/Users';
import { Pagination } from '../../../types/models/Pagination';
import { PromoOffer, PromoOfferStatus } from '../../../types/models/PromoOffer';
import { BaseResponse } from '../../../types/models/BaseResponse';
import { FullDingProduct } from '../../../types/models/FullDingProduct';
import { FullDingPromotion } from '../../../types/models/FullDingPromotion';

interface CreatePromoOfferPayload {
    name: string;
    description: string;
    validity: number;
    price: number;
    comments: string;
    startdate: number;
    endingdate: number;
    picture: string;
    type: string;
    oldPrice: number;
    status: string;
}

interface ApiResponse {
    data: PromoOffer[];
    pagination: Pagination;
}

class OffersRepository {
    private httpService: HttpService;


    constructor() {
        this.httpService = new HttpService();
        this.httpService.service();
    }

    async fetchPromos(
      page: number = 1,
      pageSize: number = 10,
      filter: string = '',
      status: PromoOfferStatus[] | undefined = undefined,
      productFullDescription : FullDingProduct | undefined = undefined,
      dingPromotionFullDescription : FullDingPromotion | undefined = undefined,
    ): Promise<ApiResponse> {
        try {
            const queryParams: any = { page, pageSize, filter };
            if (status && status.length > 0) {
                queryParams.status = status.join(',');
            }

            const response = await this.httpService.get<any>('/admin/topups/offers');
            const promosData = response.data;

            const { data, pagination } = promosData;

            const promos: PromoOffer[] = data.map((promo: any) => new PromoOffer(
              promo.id,
              promo.name,
              promo.description,
              promo.userId,
              promo.validity,
              promo.bonus, //
              promo.price,
              promo.vipPrice,//
              promo.costPrice,//
              promo.revPercent,//
              promo.oldPrice,
              promo.type,
              promo.status,
              promo.variant,
              promo.comments,
              promo.infoLabel,
              promo.startDate,
              promo.endingDate,
              promo.picture,
              productFullDescription,
              dingPromotionFullDescription,
              promo.createdAt
            ));

            return {data: promos, pagination };
        } catch (error) {
            console.error('Failed to fetch promos:', error);
            throw new Error('Failed to fetch promos');
        }
    }

    async createPromoOffer(promoOfferData: {
        name: string;
        description: string;
        validity: number;
        bonus: string;
        price: number;
        vipPrice : number;
        costPrice: number;
        revPercent: number;
        comments: string;
        startDate: number;
        endingDate: number;
        picture: string;
        productFullDescription : FullDingProduct | null;
        dingPromotionFullDescription : FullDingPromotion | null;
    }) {
        try {
            const response = await this.httpService.push<BaseResponse<PromoOffer>, typeof promoOfferData>(
              '/topups/offers',
              promoOfferData
            );
            console.log("createPromoOffer response", response);
            return response;
        } catch (error) {
            console.error('Error creating promo offer:', error);
            throw error;
        }
    }

    async updatePromoOffer(promoOfferId: string, promoOfferData: {

        name: unknown;
        description: unknown;
        validity: unknown;
        bonus: unknown;
        price: unknown;
        vipPrice: unknown;
        costPrice: unknown;
        revPercent: unknown;
        variant: unknown;
        comments: unknown;
        startDate: number;
        endingDate: number;
        picture: string;
        type: unknown;
        oldPrice: unknown;
        status: unknown;
        productFullDescription: FullDingPromotion | null;
        dingPromotionFullDescription: FullDingPromotion | null;
    }) {

        console.log("updatePromoOffer request", promoOfferData);
        try {
            const response = await this.httpService.update<BaseResponse<PromoOffer>, typeof promoOfferData>(
              `/topups/offers/${promoOfferId}`,
              promoOfferData
            );
            // console.log("updatePromoOffer response", response);
            return response;
        } catch (error) {
            console.error('Error updating promo offer:', error);
            throw error;
        }
    }


    async deletePromoOffer(promoOfferId: string) {
        try {
            const response = await this.httpService.remove<BaseResponse<PromoOffer>>(
              `/topups/offers/${promoOfferId}`
            );
            console.log("deletePromoOffer response", response);
            return response;
        } catch (error) {
            console.error('Error deleting promo offer:', error);
            throw error;
        }
    }




}

export default OffersRepository;
