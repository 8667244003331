import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';

import CubacelOffersToManage from './CubacelOffersToManage';
import { useState } from 'react';

function PromoOffersCubacel() {

  const [isSuccess, setIsSuccess] = useState(false);

  const handleSuccess = () => {
    setIsSuccess(true);
  };

  return (
    <>
      <Helmet>
        <title>Ofertas</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader onSuccess={handleSuccess} />
      </PageTitleWrapper>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <CubacelOffersToManage isSuccess={isSuccess}/>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default PromoOffersCubacel;
