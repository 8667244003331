import HttpService from '../../../../services/api/HttpService';
import { Users, UserStatus } from '../../../../types/models/Users';
import axios from 'axios';
import { Pageable } from '../../../../types/models/Pageable';
import { PaymentMethods, RecentActivityData, UserProfile } from '../../../../types/models/UserProfile';

interface AddToCartPayload {
    offerId: string;
    userId: string;
    contactToCharge: string;
}

interface ApiResponse {
    data: UserProfile;
}
class ProfileManagementRepository {
    private httpService: HttpService;


    constructor() {
        this.httpService = new HttpService();
        this.httpService.service();
    }

    async fetchProfile(id: string): Promise<ApiResponse> {
        try {
            // Make the HTTP GET request to fetch the single user profile
            const response = await this.httpService.get<any>(`/user/profile/${id}`);
            const responseData = response.data;

            // Extract the single user profile from the response data
            const user = {
                id: responseData.id,
                name: responseData.name,
                photoURL: responseData.photoURL,
                email: responseData.email,
                phone: responseData.phone,
                agencyId: responseData.agencyId,
                createdAt: responseData.createdAt,
                storeAccess: responseData.storeAccess,
                itIsBlocked: responseData.itIsBlocked,
                status: responseData.status,
                emailNotificationsAllowed: responseData.emailNotificationsAllowed,
                smsNotificationsAllowed: responseData.smsNotificationsAllowed,
                emailConfirmed: responseData.emailConfirmed,
                phoneNumberConfirmed: responseData.phoneNumberConfirmed,
                roleIds: responseData.roleIds,
                recentActivity: responseData.recentActivity,
                paymentMethods: responseData.paymentMethods,

            };

            return { data: user };
        } catch (error) {
            console.error('Failed to fetch user profile:', error);
            throw new Error('Failed to fetch user profile');
        }
    }

}

export default ProfileManagementRepository;
