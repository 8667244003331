import React from 'react';
import { Grid, Typography } from '@mui/material';
import PromotionCard from './PromotionCard';
import { FullDingPromotion } from '../../types/models/FullDingPromotion';
import DingConnectRepository from './DingConnectRepository';

const PromotionCards: React.FC = () => {
  const [fullDingPromotions, setFullDingPromotions] = React.useState<FullDingPromotion[]>([]);
  const dingConnectRepository = new DingConnectRepository();

  React.useEffect(() => {
    dingConnectRepository.fetchFullDingPromotions().then(fullDingPromotionsResponse =>{
      console.log('fullDingPromotionsResponse', fullDingPromotionsResponse)
      setFullDingPromotions(fullDingPromotionsResponse)
    }).catch((error) => {
      console.error('Failed to fetch Account Balance:', error);
    });
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Promociones
      </Typography>
      <Grid container spacing={2}>
        {fullDingPromotions.map((promotion, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <PromotionCard promotion={promotion} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PromotionCards;


