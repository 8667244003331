import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography, Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import GridOnTwoToneIcon from '@mui/icons-material/GridOnTwoTone';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import axios from 'axios';
import DingConnectRepository from './DingConnectRepository';
import { Users } from '../../types/models/Users';
import { BalanceResponse } from '../../types/models/DingConnectBalance';
import { FullDingPromotion } from '../../types/models/FullDingPromotion';


const TotalCredit: React.FC = () => {

  const [balanceData, setBalanceData] = React.useState<BalanceResponse>();

  const dingConnectRepository = new DingConnectRepository();


  React.useEffect(() => {
    dingConnectRepository.fetchAccountBalance().then(responseData =>{
      console.log('data', responseData)
      setBalanceData(responseData)
  }).catch((error) => {
    console.error('Failed to fetch Account Balance:', error);
  });
}, []);



  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" className="MuiBox-root css-1jm6vcx">
      <Box className="MuiBox-root css-70qvj9">
        <Paper elevation={1} style={{ padding : 10}}>
          <Avatar className="MuiAvatar-root MuiAvatar-rounded MuiAvatar-colorDefault css-1ihdvgn">
            <AutoModeIcon className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-6flbmm" />
          </Avatar>
        </Paper>

        <Box style={{marginLeft:12}} className="MuiBox-root css-0">
          <Typography variant="h3" className="MuiTypography-root MuiTypography-h3 MuiTypography-gutterBottom css-5pr7zc">
            DingConnect
          </Typography>
          <Typography variant="subtitle2" className="MuiTypography-root MuiTypography-subtitle2 css-16vv0qu">
            Panel de control de DingConnect, proveedor de servicios de comunicación.
          </Typography>
        </Box>
      </Box>

      <Paper elevation={1} style={{ minWidth : 300}}>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <div>
            <Typography variant="h4" gutterBottom>
              Saldo
            </Typography>
            <Typography variant="h5">DingConnect</Typography>
          </div>
          <Divider orientation="vertical" className="MuiDivider-root MuiDivider-fullWidth MuiDivider-vertical MuiDivider-flexItem css-r5swil" />
          <div>
            {/*<Typography variant="h4" gutterBottom>*/}
            {/*  Components*/}
            {/*</Typography>*/}
            <Box display="flex" alignItems="center">
          <span
            className="css-c432v2"
            style={{ animation: 'pulse 1s ease infinite' }}
          ></span>
              {balanceData && ( // Check if balanceData exists
                <Typography variant="h2" className="css-17e0hwb">
                  ${balanceData.balance} {balanceData.currencyIso}
                </Typography>
              )}
            </Box>
          </div>
        </Box>
      </Paper>
    </Box>
  );
};

// <Divider className="MuiDivider-root MuiDivider-fullWidth MuiDivider-vertical MuiDivider-flexItem css-r5swil" />
export default TotalCredit;
