import { Card } from '@mui/material';
import { CryptoOrder } from 'src/models/crypto_order';
import UsersTable from './UsersTable';
import { subDays } from 'date-fns';
import { Users } from '../../../types/models/Users';
import UsersManagementRepository from './UsersManagementRepository';
import React from 'react';

function UsersToManage() {


  return (
    <Card>
      <UsersTable />
    </Card>
  );
}

export default UsersToManage;
