import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { EHttpMethod } from "../../types/enums";
import { IParams } from "../../types/services";
import { useNavigate } from 'react-router-dom';


export class HttpService {
    private http: AxiosInstance;

     private baseURL = process.env.REACT_APP_BASE_URL;
     //private baseURL = process.env.REACT_APP_BASE_URL_DEV;


    constructor() {
        this.http = axios.create({
            baseURL: this.baseURL,
            withCredentials: false,
            headers: this.setupHeaders()
        });
    }

    private getAuthorization() {
        const token = localStorage.getItem("token");
        const accessToken = Cookies.get("AccessToken") || "";
        return token ? { Authorization: `Bearer ${token}` } : {};
    }

    public service() {
        this.injectInterceptors();
        return this;
    }

    private setupHeaders(hasAttachment = false) {
        const authorizationHeaders = this.getAuthorization();
        const userAgent = navigator.userAgent;
        const deviceType = this.getDeviceType();
        const screenResolution = this.getScreenResolution();
        const userId = this.getUserId();
        const browserLanguage = navigator.language;

        return {
            "Content-Type": hasAttachment ? "multipart/form-data" : "application/json",
            ...authorizationHeaders,
            'Hash': 'Qbolacel',
            //'User-Agent': userAgent,
            'Device-Type': deviceType,
            'Screen-Resolution': screenResolution,
            'Platform': "Web",
            'User-Id' : userId,
            'Browser-Language': browserLanguage
        };
    }

    private async request<T>(
        method: EHttpMethod,
        url: string,
        options: AxiosRequestConfig
    ): Promise<T> {
        try {
            const response: AxiosResponse<T> = await this.http.request<T>({
                method,
                url,
                ...options,
            });

            return response.data;
        } catch (error) {
            return this.normalizeError(error);
        }
    }

    public async get<T>(
        url: string,
        params?: IParams,
        hasAttachment = false
    ): Promise<T> {
        return this.request<T>(EHttpMethod.GET, url, {
            params,
            headers: this.setupHeaders(hasAttachment),
        });
    }

    public async push<T, P>(
        url: string,
        payload: P,
        params?: IParams,
        hasAttachment = false
    ): Promise<T> {
        return this.request<T>(EHttpMethod.POST, url, {
            params,
            data: payload,
            headers: this.setupHeaders(hasAttachment),
        });
    }

    public async update<T, P>(
        url: string,
        payload: P,
        params?: IParams,
        hasAttachment = false
    ): Promise<T> {
        return this.request<T>(EHttpMethod.PUT, url, {
            params,
            data: payload,
            headers: this.setupHeaders(hasAttachment),
        });
    }

    public async remove<T>(
        url: string,
        params?: IParams,
        hasAttachment = false
    ): Promise<T> {
        return this.request<T>(EHttpMethod.DELETE, url, {
            params,
            headers: this.setupHeaders(hasAttachment),
        });
    }

    // private injectInterceptors() {
    //     this.http.interceptors.request.use((request) => {
    //         // * Perform an action
    //         return request;
    //     });
    //
    //     this.http.interceptors.response.use(
    //         (response) => {
    //             // * Do something
    //             return response;
    //         },
    //         (error) => {
    //             // * Implement a global error alert
    //             return Promise.reject(error);
    //         }
    //     );
    // }

    private injectInterceptors() {
        const navigate = useNavigate();

        this.http.interceptors.response.use(
          (response) => {
              // Handle successful responses
              return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                  // Redirect to login page on 401 error
                  navigate('/signin');
              } else {
                  // Handle other error responses
                  console.error('HTTP Error:', error);
              }
              return Promise.reject(error);
          }
        );
    }




    private normalizeError(error: any) {
        return Promise.reject(error);
    }

    private getDeviceType(): string {
        const userAgent: string = navigator.userAgent;
        const isMobile: boolean = /Mobi/.test(userAgent); // Check if the user agent indicates a mobile device
        return isMobile ? 'Mobile' : 'Desktop';
    }

    private getScreenResolution(): string {
        return `${window.screen.width}x${window.screen.height}`;
    }

    private getUserId(): string {
        const userId = localStorage.getItem("id");
        return userId !== null ? userId : ""; // If userId is not null, return its value, otherwise return an empty string
    }

}

export { HttpService as default };
