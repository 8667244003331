import { Card } from '@mui/material';
import { Transaction } from 'src/models/transaction_order';
import RecentOrdersTable from './RecentOrdersTable';
import { subDays } from 'date-fns';
import TransactionsRepository from './TransactionsRepository';
import React, { useEffect, useState } from 'react';

function RecentOrders() {



  return (
    <Card>
      <RecentOrdersTable />
    </Card>
  );
}

export default RecentOrders;
