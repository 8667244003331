import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Container, Grid } from '@mui/material';
import Footer from 'src/components/Footer';
import TotalCredit from './TotalCredit';
import PromotionCards from './PromotionCards';
import ProductCards from './ProductCards';

function DingConnect() {

  return (
    <>
      <Helmet>
        <title>DingConnect Gestión General</title>
      </Helmet>
      <PageTitleWrapper>
        <TotalCredit />
      </PageTitleWrapper>
      {/* Apply background color to the Container component */}
      <Container maxWidth="xl" sx={{ backgroundColor: 'rgb(242, 245, 249)' }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={12}>
            <PromotionCards />
          </Grid>
          <Grid item xs={12}>
            <ProductCards />
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <AccountBalance />*/}
          {/*</Grid>*/}
          {/*<Grid item lg={8} xs={12}>*/}
          {/*  <Wallets />*/}
          {/*</Grid>*/}
          {/*<Grid item lg={4} xs={12}>*/}
          {/*  <AccountSecurity />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12}>*/}
          {/*  <WatchList />*/}
          {/*</Grid>*/}
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default DingConnect;
