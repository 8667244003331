import { NetworkCalls } from 'src/types/models/NetworkCalls';
import HttpService from '../../../services/api/HttpService';
import { Pagination } from '../../../types/models/Pagination';
import { Pageable } from '../../../types/models/Pageable';

interface AddToCartPayload {
    offerId: string;
    userId: string;
    contactToCharge: string;
}

interface ApiResponse {
    data: NetworkCalls[];
    pageable: Pageable;
    totalPages: number;
    totalElements: number;
    last: boolean;
    numberOfElements: number;
    size: number;
    number: number;
    sort: {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    };
    first: boolean;
    empty: boolean;
}

class NetworkCallsRepository {
    private httpService: HttpService;

    constructor() {
        this.httpService = new HttpService();
        this.httpService.service();
    }

    async fetchNetworkCalls(
      page: number = 0,
      pageSize: number = 10,
      filter: string = '',
      status: NetworkCalls[] | undefined = undefined
    ): Promise<ApiResponse> {
        try {
            // Construct the query parameters
            const queryParams: any = { page, pageSize, filter };
            if (status && status.length > 0) {
                queryParams.status = status.join(',');
            }

            const response = await this.httpService.get<any>(`/administration/networkCalls?page=${page}&pageSize=${pageSize}&filter=${filter}&status=${status}`, {

            });
            const responseData = response.data;

            const { content, pageable, totalPages, totalElements } = responseData;

            const networkCalls: NetworkCalls[] = content.map((networkCall: any) => ({
                id: networkCall.id,
                url: networkCall.url || "",
                userInfo: networkCall.userInfo ? {
                    id: networkCall.userInfo.id || "",
                    name: networkCall.userInfo.name || "",
                    role: networkCall.userInfo.role || ""
                } : null,
                deviceType: networkCall.deviceType || null,
                browserLanguage: networkCall.browserLanguage || null,
                userAgent: networkCall.userAgent || null,
                platform: networkCall.platform || networkCall.systemOs || null,
                systemOs: networkCall.systemOs || null,
                origin: networkCall.origin || null,
                referer: networkCall.referer || null,
                createdAt: networkCall.createdAt
            }));


            return { data: networkCalls, ...pageable , totalPages, totalElements};
        } catch (error) {
            console.error('Failed to fetch networkCalls:', error);
            throw new Error('Failed to fetch networkCalls');
        }
    }
}

export default NetworkCallsRepository;
