import HttpService from '../../../services/api/HttpService';
import { Pagination } from '../../../types/models/Pagination';
import { Transaction, TransactionOrderStatus } from 'src/models/transaction_order';
import { Pageable } from '../../../types/models/Pageable';


interface ApiResponse {
    data: Transaction[];
    pageable: Pageable;
    totalPages: number;
    totalElements: number;
    last: boolean;
    numberOfElements: number;
    size: number;
    number: number;
    sort: {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    };
    first: boolean;
    empty: boolean;
}

class TransactionsRepository {
    private httpService: HttpService;


    constructor() {
        this.httpService = new HttpService();
        this.httpService.service();
    }


    async fetchTransactions(
      page: number = 0,
      pageSize: number = 10,
      filter: string = '',
      status: TransactionOrderStatus[] = ['PENDING', 'COMPLETED', 'CANCELLED', 'REFUNDED', 'DISPUTED', 'FAILED']
    ): Promise<ApiResponse> {
        try {
            // Construct the query parameters
            const queryParams: any = { page, pageSize, filter };
            if (status && status.length > 0) {
                queryParams.status = status.join(',');
            }

            const response = await this.httpService.get<any>(`/payments/transactions?page=${page}&pageSize=${pageSize}&filter=${filter}&status=${status}`, {
               // params: queryParams
            });
            const responseData = response.data;

            const { content, pageable, totalPages, totalElements } = responseData;

            console.log('Response: ', response)
            console.log('ResponseData: ', responseData)
            console.log('Data: ', content)
            // Map the data to your Users objects
            const transactions: Transaction[] = content.map((transaction: any) => ({
                id: transaction.id,
                serviceType: transaction.serviceType,
                account: transaction.account,
                date: transaction.date,
                price: transaction.price,
                costPrice: transaction.costPrice,
                revPercent: transaction.revPercent,
                priceText: transaction.priceText,
                bonus: transaction.bonus,
                discount: transaction.discount,
                status: transaction.status,
                statusDescription: transaction.statusDescription,
                userAgentType: transaction.userAgentType,
                sendRechargeNow: transaction.sendRechargeNow,
                rechargeAmount: transaction.rechargeAmount,
                userId: transaction.userId,
                cubAccount: transaction.cubAccount,
                contactName: transaction.contactName,
                contactId: transaction.contactId,
                offerId: transaction.offerId,
                offerAmountText: transaction.offerAmountText,
                offerAmountFullText: transaction.offerAmountFullText,
                bonusDescription: transaction.bonusDescription,
                bonusExpDate: transaction.bonusExpDate,
                createdAt: transaction.createdAt
            }));

            return { data: transactions, ...pageable , totalPages, totalElements};
        } catch (error) {
            console.error('Failed to fetch transactions:', error);
            throw new Error('Failed to fetch transactions');
        }
    }
}

export default TransactionsRepository;
