import HttpService from '../../../services/api/HttpService';
import {AuthUser} from "../../../types/models/AuthUser";
import {BaseResponse} from "../../../types/models/BaseResponse";
import { PromoOffer } from '../../../types/models/PromoOffer';
import { User } from '../../../types/models/User';

interface RequestPayload {
    username: string;
    password: string;
}

interface FirebaseRequestPayload {
    name: string;
    email: string;
    phone: string;
    photoURL: string;
    uid: string;
    idToken: string;
    provider: string;
    agencyId: string;
    emailVerified: boolean;
}

class UsersRepository {
    private httpService: HttpService;

    constructor() {
        this.httpService = new HttpService();
        this.httpService.service();
    }

    authenticateUser(username: string, password: string): Promise<BaseResponse<AuthUser>> {
        return this.httpService.push<BaseResponse<AuthUser>, RequestPayload>('/authenticate', {
            username: username,
            password: password
        });
    }

    authenticateFirebaseUser(
      name: string,
      email: string,
      phone: string,
      photoURL: string,
      uid: string,
      idToken: string,
      provider: string,
      emailVerified: boolean,
    ): Promise<BaseResponse<AuthUser>> {
        return this.httpService.push<BaseResponse<AuthUser>, FirebaseRequestPayload>('/firebase', {
            name: name,
            email: email,
            phone: phone,
            photoURL: photoURL,
            uid: uid,
            idToken: idToken,
            provider: provider,
            agencyId : "QbolaCel",
            emailVerified: emailVerified
        });
    }

    async updateUser(userId: string, userData: {
        name: string;
        email: string;
        phone: string;
        agencyId: string;
        status: string;
        emailNotificationsAllowed: boolean;
        smsNotificationsAllowed: boolean;
        emailConfirmed: boolean;
        phoneNumberConfirmed: boolean;
        storeAccess:boolean;
    }) {
        try {
            const response = await this.httpService.update<BaseResponse<User>, typeof userData>(
              `/profile/${userId}`,
              userData
            );
            console.log("updateUser response", response);
            return response;
        } catch (error) {
            console.error('Error updating User:', error);
            throw error;
        }
    }


    async deleteUser(userId: string) {
        try {
            const response = await this.httpService.remove<BaseResponse<User>>(
              `/profile/${userId}`
            );
            console.log("deleteUser response", response);
            return response;
        } catch (error) {
            console.error('Error deleting User:', error);
            throw error;
        }
    }
}

export default UsersRepository;
