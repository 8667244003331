import { Typography, Button, Grid } from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

function PageHeader() {
  const userName = localStorage.getItem("username");
  const user = {
    //name: 'Catherine Pike',
    avatar: '/static/images/avatars/1.jpg'
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Usuarios
        </Typography>
        <Typography variant="subtitle2">
          {userName}, estos son los usuarios registrados.
        </Typography>
      </Grid>
      {/*<Grid item>*/}
      {/*  <Button*/}
      {/*    sx={{ mt: { xs: 2, md: 0 } }}*/}
      {/*    variant="contained"*/}
      {/*    startIcon={<AddTwoToneIcon fontSize="small" />}*/}
      {/*  >*/}
      {/*    Crear usuario*/}
      {/*  </Button>*/}
      {/*</Grid>*/}
    </Grid>
  );
}

export default PageHeader;
