import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid, Card, CardHeader, Divider, Avatar
} from '@mui/material';
import { UserStatus } from '../../../types/models/Users';
import { UserProfile } from '../../../types/models/UserProfile';
import UsersRepository from '../../pages/SignIn/UsersRepository';

const statusOptions = [
  { id: 'PENDING', name: 'Pendiente' },
  { id: 'BLOCKED', name: 'Bloqueado' },
  { id: 'ACTIVE', name: 'Activo' },
  { id: 'REMOVED', name: 'Removido' }
];

interface UserSettingsProps {
  userProfile?: UserProfile,
  userUpdated?: () => void
}

function UserSettings({ userProfile, userUpdated }: UserSettingsProps) {
  const [name, setName] = useState(userProfile.name);
  const [email, setEmail] = useState(userProfile.email);
  const [phone, setPhone] = useState(userProfile.phone);
  const [agencyId, setAgencyId] = useState(userProfile.agencyId);
  const [status, setStatus] = useState<UserStatus>(userProfile.status);
  const [emailNotificationsAllowed, setEmailNotificationsAllowed] = useState(userProfile.emailNotificationsAllowed);
  const [smsNotificationsAllowed, setSmsNotificationsAllowed] = useState(userProfile.emailNotificationsAllowed);
  const [emailConfirmed, setEmailConfirmed] = useState(userProfile.emailConfirmed);
  const [phoneNumberConfirmed, setPhoneNumberConfirmed] = useState(userProfile.phoneNumberConfirmed);
  const [storeAccess, setStoreAccess] = useState(userProfile.storeAccess);
  const usersRepository = new UsersRepository();

  const handleSave = () => {
    const updatedUserData = {
      name,
      email,
      phone,
      agencyId,
      status,
      emailNotificationsAllowed,
      smsNotificationsAllowed,
      emailConfirmed,
      phoneNumberConfirmed,
      storeAccess
    };

    console.log('updatedUserData: ', updatedUserData);
    usersRepository.updateUser(userProfile.id, updatedUserData).then((data) => {
      userUpdated();

    }).catch(error => {
      console.error('Error updating promo offer:', error);

    });

  };

  // useEffect(() => {
  //   setName(defaultUserData.name);
  //   setEmail(defaultUserData.email);
  //   setPhone(defaultUserData.phone);
  //   setAgencyId(defaultUserData.agencyId);
  //   setStatus(defaultUserData.status);
  //   setEmailNotificationsAllowed(defaultUserData.emailNotificationsAllowed);
  //   setSmsNotificationsAllowed(defaultUserData.smsNotificationsAllowed);
  //   setEmailConfirmed(defaultUserData.emailConfirmed);
  //   setPhoneNumberConfirmed(defaultUserData.phoneNumberConfirmed);
  // }, [defaultUserData]);

  return (
    <Card>
      <CardHeader title="Edición de Usuarios" />
      <Divider />
      <Box p={3}>
        <Grid container spacing={2}>
          {/* User Info Section */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Información del Usuario
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Nombre"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ width: '100%', maxWidth: '250px', marginBottom: 1 }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Correo electrónico"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ width: '100%', maxWidth: '250px', marginBottom: 1 }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Teléfono"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              sx={{ width: '100%', maxWidth: '200px', marginBottom: 1 }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="ID de compañía"
              variant="outlined"
              value={agencyId}
              onChange={(e) => setAgencyId(e.target.value)}
              sx={{ width: '100%', maxWidth: '200px', marginBottom: 1 }}
            />
          </Grid>

          {/* Status and Notification Section */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Preferencias
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ maxWidth: '250px', marginBottom: 1 }}>
              <InputLabel>Estado del usuario</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value as UserStatus)}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Notification Switches */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={emailNotificationsAllowed}
                  onChange={() => setEmailNotificationsAllowed(!emailNotificationsAllowed)}
                />
              }
              label="Notificaciones por correo"
              sx={{ marginBottom: 1 }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={smsNotificationsAllowed}
                  onChange={() => setSmsNotificationsAllowed(!smsNotificationsAllowed)}
                />
              }
              label="Notificaciones por SMS"
              sx={{ marginBottom: 1 }}
            />
          </Grid>

          {/* Confirmation Switches */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={emailConfirmed}
                  onChange={() => setEmailConfirmed(!emailConfirmed)}
                />
              }
              label="Correo confirmado"
              sx={{ marginBottom: 1 }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={phoneNumberConfirmed}
                  onChange={() => setPhoneNumberConfirmed(!phoneNumberConfirmed)}
                />
              }
              label="Teléfono confirmado"
              sx={{ marginBottom: 1 }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={storeAccess}
                  onChange={() => setStoreAccess(!storeAccess)}
                />
              }
              label="Acceso a tienda"
              sx={{ marginBottom: 1 }}
            />
          </Grid>

          {/* Action Buttons */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="outlined" sx={{ mx: 1 }} onClick={handleSave}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={handleSave}>
                Guardar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>


  );

}

export default UserSettings;
