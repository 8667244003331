import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader, DialogContent, DialogContentText, Button, Avatar, Link
} from '@mui/material';

import Label from 'src/components/Label';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import BulkActions from './BulkActions';
import { Users, UserStatus } from '../../../types/models/Users';
import UserManagementDialog from '../../pages/Components/Modals/UserManagementDialog';
import UsersManagementRepository from './UsersManagementRepository';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import UsersRepository from '../../pages/SignIn/UsersRepository';
import { UserRole } from '../../../types/enums';
import { Link as RouterLink } from 'react-router-dom';

interface UsersTableProps {
  className?: string;
}

interface Filters {
  status?: UserStatus;
}

const getStatusLabel = (userStatus : UserStatus): JSX.Element => {
  const map = {
    REMOVED: {
      text: 'Borrado',
      color: 'error'
    },
    BLOCKED: {
      text: 'Bloqueado',
      color: 'error'
    },
    ACTIVE: {
      text: 'Activo',
      color: 'success'
    },
    PENDING: {
      text: 'Pendiente',
      color: 'warning'
    }
  };

  const { text, color }: any = map[userStatus];

  return <Label color={color}>{text}</Label>;
};

const getRoleLabel = (userStatus : UserRole): JSX.Element => {
  const map = {
    ADMIN: {
      text: 'Administrador',
      color: 'error'
    },
    STAFF: {
      text: 'Staff',
      color: 'primary'
    },
    USER: {
      text: 'Usuario',
      color: 'success'
    },
    SUPERVISOR: {
      text: 'Supervisor',
      color: 'warning'
    }
  };

  const { text, color }: any = map[userStatus];

  return <Label color={color}>{text}</Label>;
};
function getUserRole(roleId: string): UserRole {
  // Map roleId to UserRole

  const roleIdToUserRoleMap: { [key: string]: UserRole } = {
    "65f53af8d470c2149a1b35dd": UserRole.USER,
    "65f53b1cd470c2149a1b35df": UserRole.STAFF,
    "65f53b26d470c2149a1b35e1": UserRole.SUPERVISOR,
    "65f53b55d470c2149a1b35e3": UserRole.ADMIN,

  };

  // Get UserRole based on roleId
  const userRole = roleIdToUserRoleMap[roleId] || UserRole.USER;

  return userRole;
}
const getNotificationsLabel = (emailStatus: boolean, smsStatus: boolean): JSX.Element => {
  const mapEmail = {
    false: {
      text: 'Correo : No',
      color: 'error'
    },
    true: {
      text: 'Correo : Si',
      color: 'success'
    },
  };

  const mapSMS = {
    false: {
      text: 'SMS : No',
      color: 'error'
    },
    true: {
      text: 'SMS : Si',
      color: 'success'
    },
  };

  const { text: emailText, color: emailColor } = mapEmail[emailStatus.toString()];
  const { text: smsText, color: smsColor } = mapSMS[smsStatus.toString()];

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div style={{ marginRight: '10px' }}>
        <Label color={emailColor as 'error' | 'success'}>{emailText}</Label>
      </div>
      <Label color={smsColor as 'error' | 'success'}>{smsText}</Label>
    </div>
  );




};

const applyFilters = (
  users: Users[],
  filters: Filters
): Users[] => {
  return users.filter((users) => {
    let matches = true;

    if (filters.status && users.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  users:Users[],
  page: number,
  limit: number
): Users[] => {
  return users.slice(page * limit, page * limit + limit);
};

const UsersTable: FC<UsersTableProps> = ({  }) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedUsers.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [usersList, setUsersList] = React.useState<Users[]>([]);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const usersManagementRepository = new UsersManagementRepository();
  const [openDeleteDialogs, setOpenDeleteDialogs] = useState<boolean[]>(new Array(totalItems).fill(false));
  const usersRepository = new UsersRepository()
  const roles = localStorage.getItem("rid");
  const baseURL = process.env.REACT_APP_BASE_URL || '';

  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  React.useEffect(() => {

    usersManagementRepository.fetchUsers(page, limit, filters.status).then((data) => {
      setUsersList(data.data)
      setTotalItems(data.totalElements);
    }).catch((error) => {
      console.error('Failed to fetch users:', error);
    });

  }, [page, limit]);

  const handleDeleteClickOpen = (index: number) => {
    const newOpenDeleteDialogs = [...openDeleteDialogs];
    newOpenDeleteDialogs[index] = true;
    setOpenDeleteDialogs(newOpenDeleteDialogs);
  };

  const handleDeleteClickClose = (index: number) => {
    const newOpenDeleteDialogs = [...openDeleteDialogs];
    newOpenDeleteDialogs[index] = false;
    setOpenDeleteDialogs(newOpenDeleteDialogs);
  };

  const handleDeleteUser = (user, index) =>{
    usersRepository.deleteUser(user.id).then(data => {
      handleOffersUpdate()
      handleDeleteClickClose(index);
    }).catch(error => {
      console.error('Error deleting promo offer:', error);
    });
  };


  //
  // useEffect(() => {
  //   setUsersList(userstoShow);
  // }, [userstoShow]);

  const statusOptions = [
    {
      id: 'all',
      name: 'Todos'
    },
    {
      id: 'ACTIVE',
      name: 'Activos'
    },
    {
      id: 'PENDING',
      name: 'Pendientes'
    },
    {
      id: 'REMOVED',
      name: 'Borrados'
    },
    {
      id: 'BLOCKED',
      name: 'Bloqueados'
    }
  ];

  const handleOffersUpdate = () => {
    // usersManagementRepository.fetchUsers().then((data) => {
    //   setUsersList(data.data)
    // }).catch((error) => {
    //   console.error('Failed to fetch users:', error);
    // });
  };

  const handleEditUser = (user) => {
    setUserToEdit(user);
    setOpenEditUserDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenEditUserDialog(false);
    setUserToEdit(null);
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  // const handleSelectedUsers = (
  //   event: ChangeEvent<HTMLInputElement>
  // ): void => {
  //   setSelectedUsers(
  //     event.target.checked
  //       ? usersList.map((users) => users.id)
  //       : []
  //   );
  // };

  const handleSelectOneUser = (
    event: ChangeEvent<HTMLInputElement>,
    userId: string
  ): void => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelected) => [
        ...prevSelected,
        userId
      ]);
    } else {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((id) => id !== userId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredUsers = applyFilters(usersList, filters);
  // const paginatedUsers = applyPagination(
  //   filteredUsers,
  //   page,
  //   limit
  // );
  const selectedSomeUsers =
    selectedUsers.length > 0 &&
    selectedUsers.length < usersList.length;
  const selectedAllUsers =
    selectedUsers.length === usersList.length;
  const theme = useTheme();

  return (
    <Card>
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Estado</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Estado"
                  autoWidth
                >

                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          title="Usuarios Registrados"
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Detalles de Usuario</TableCell>
              <TableCell>Contacto</TableCell>
              {/*<TableCell>Agencia</TableCell>*/}
              <TableCell align="right">Rol</TableCell>
              <TableCell align="right">Notificaciones</TableCell>
              <TableCell align="right">Estado</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user, index) => {
              const isUserSelected = selectedUsers.includes(
                user.id
              );
              const roleId = user.roleIds[0];
              return (
                <TableRow
                  hover
                  key={user.id}
                >


                  <TableCell>

                    <div style={{ display: 'flex', justifyContent: 'start' }}>
                      <div style={{ marginRight: '10px' }}>
                        <Avatar
                          sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }}
                          alt={user.name}
                          src={user.photoURL}
                        />
                      </div>
                      <Box>
                        <Typography variant="body1" fontWeight="bold" gutterBottom noWrap>
                          <Link
                            component={RouterLink}
                            to={`/user/profile/${user.id}`}
                            style={{ color: 'rgb(85, 105, 255)', textDecoration: 'none' }}
                            sx={{
                              '&:hover, &:focus': {
                                textDecoration: 'underline',
                              },
                              '&:active': {
                                textDecoration: 'underline', // You can adjust this style for the active state as needed
                              },
                            }}
                          >
                            {user.name}
                          </Link>
                        </Typography>

                        <Typography variant="body2" color="text.secondary" noWrap>
                          {format(user.createdAt, 'MMMM dd yyyy')}
                        </Typography>
                      </Box>
                    </div>

                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {user.email}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {user.phone}
                    </Typography>
                  </TableCell>
                  {/*<TableCell>*/}
                  {/*  <Typography*/}
                  {/*    variant="body1"*/}
                  {/*    fontWeight="bold"*/}
                  {/*    color="text.primary"*/}
                  {/*    gutterBottom*/}
                  {/*    noWrap*/}
                  {/*  >*/}
                  {/*  {user.agencyId}*/}
                  {/*  </Typography>*/}
                  {/*</TableCell>*/}

                  <TableCell align="right">
                    {/*{getRoleLabel((getUserRole(user.roleIds[0])))}*/}

                    {getRoleLabel(getUserRole(roleId))}
                  </TableCell>

                  <TableCell align="center">
                    {getNotificationsLabel(user.emailNotificationsAllowed, user.smsNotificationsAllowed)}
                  </TableCell>

                  <TableCell align="right">
                    {getStatusLabel(user.status)}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Información de Usuario" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {(getUserRole(roles) === UserRole.ADMIN || getUserRole(roles) === UserRole.SUPERVISOR || getUserRole(roles) === UserRole.STAFF) && (
                      <Tooltip title="Editar Usuario" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                          onClick={() => handleEditUser(user)}>
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {(getUserRole(roles) === UserRole.ADMIN) && (

                      <>
                        <Tooltip title="Borrar Usuario" arrow>
                          <IconButton
                            sx={{
                              '&:hover': { background: theme.colors.error.lighter },
                              color: theme.palette.error.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => handleDeleteClickOpen(index)}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Dialog
                          open={openDeleteDialogs[index]}
                          onClose={() => handleDeleteClickClose(index)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Eliminar la información del usuario??"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Estás seguro que desea eliminar la información del usuario? Este cambio no se puede deshacer.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => handleDeleteClickClose(index)}>Cancelar</Button>
                            <Button
                              onClick={()=> handleDeleteUser(user, index)} autoFocus>
                              Eliminar
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={totalItems}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 30]}
        />
      </Box>
      <UserManagementDialog
        open={openEditUserDialog}
        onClose={handleCloseDialog}
        userData={userToEdit}
        onSave={handleOffersUpdate}
      />

    </Card>
  );
};

// UsersTable.propTypes = {
//   userstoShow: PropTypes.array.isRequired
// };
//
// UsersTable.defaultProps = {
//   userstoShow: []
// };

export default UsersTable;
