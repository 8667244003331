import { Card } from '@mui/material';
import CubacelOffersTable from './CubacelOffersTable';
import OffersRepository from '../OffersRepository';
import React, { useEffect, useState } from 'react';
import { PromoOffer } from '../../../../types/models/PromoOffer';

interface CubacelOffersToManageProps {
  isSuccess?: boolean;
}

function CubacelOffersToManage({ isSuccess }: CubacelOffersToManageProps) {
  const [promoOffers, setPromoOffers] = useState<PromoOffer[]>([]);
  const offersRepository = new OffersRepository();


  useEffect(() => {
    const fetchPromoOffers = async () => {
      offersRepository.fetchPromos().then((data) => {
        setPromoOffers(data.data)

      }).catch((error) => {
        console.error('Failed to fetch offers:', error);
      });
    };

    fetchPromoOffers();
  }, [isSuccess]);

  return (
    <Card>
      <CubacelOffersTable promoOffersList={promoOffers} />
    </Card>
  );
}

export default CubacelOffersToManage;
