import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import { Grid, Container } from '@mui/material';
import ProfileCover from './ProfileCover';
import RecentActivity from './RecentActivity';
import Feed from './Feed';
import PopularTags from './PopularTags';
import MyCards from './MyCards';
import Addresses from './Addresses';
import ProfileManagementRepository from './ProfileManagementRepository';
import { Users } from '../../../../types/models/Users';
import { UserProfile } from '../../../../types/models/UserProfile';
import UserSettings from '../UserSettings';

function ManagementUserProfile() {
  const { id } = useParams(); // Extract ID from the route
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null); // State to store user data
  const [loading, setLoading] = useState(true); // State to handle loading
  const profileManagementRepository = new ProfileManagementRepository();

  useEffect(() => {
    // Fetch the user profile
    profileManagementRepository
      .fetchProfile(id)
      .then((response) => {
        setUserProfile(response.data); // Set the user profile
        console.log('User Profile:', response.data); // Log the fetched data
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error('Failed to fetch user profile:', error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>; // Display loading message while fetching
  }

  const userUpdated = () => {
    setLoading(true); // Set loading to true while refetching the user
    profileManagementRepository
      .fetchProfile(id)
      .then((response) => {
        setUserProfile(response.data); // Update the user profile with new data
        console.log('User Profile Updated:', response.data); // Log the updated data
        setLoading(false); // Set loading to false after updating
      })
      .catch((error) => {
        console.error('Failed to update user profile:', error);
        setLoading(false); // Handle any error by stopping the loading state
      });
  };


  return (
    <>
      <Helmet>
        <title>User Details - Management</title>
      </Helmet>
      <Container sx={{ mt: 3 }} maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} md={8}>
            <ProfileCover userProfile={userProfile} />
          </Grid>
          <Grid item xs={12} md={4}>
            <RecentActivity recentActivity={userProfile.recentActivity} />
          </Grid>
          <UserSettings userProfile={userProfile} userUpdated = {userUpdated}/>

          <Grid item xs={12} md={7}>
            <MyCards paymentMethods={userProfile.paymentMethods} />
          </Grid>
          <Grid item xs={12} md={5}>
            <Addresses />
          </Grid>
          {/*<Grid item xs={12} md={8}>*/}
          {/*  <Feed />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={4}>*/}
          {/*  <PopularTags />*/}
          {/*</Grid>*/}

        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default ManagementUserProfile;
