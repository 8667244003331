import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Typography, InputAdornment
} from '@mui/material';

import { TopUpType } from '../../offers/Cubacel/CubacelOffersTable';
import OffersRepository from '../../offers/OffersRepository';
import { formatTimestampToUtc, formatUtcToTimestamp } from '../../../../utils/utils';

const statusOptions = [
  { id: 'ACTIVE', name: 'Activa' },
  { id: 'COMING', name: 'Pendiente' },
  { id: 'DEACTIVATED', name: 'Desactivada' },
  { id: 'EXPIRED', name: 'Expirada' }
];

const PromoOfferDialog = ({ open, onClose, promoOffer, onSuccess }) => {
  const [selectedStatus, setSelectedStatus] = useState(promoOffer?.status || 'COMING');
  const [selectedType, setSelectedType] = useState(promoOffer?.type || TopUpType.CUBACEL);
  const [startDate, setStartDate] = useState(promoOffer?.startDate || '');
  const [endingDate, setEndingDate] = useState(promoOffer?.endingDate || '');
  const [name, setName] = useState(promoOffer?.name || '');
  const [description, setDescription] = useState(promoOffer?.description || '');
  const [comments, setComments] = useState(promoOffer?.comments || '');
  const [validity, setValidity] = useState(promoOffer?.validity || '');
  const [price, setPrice] = useState(promoOffer?.price || '');
  const [oldPrice, setOldPrice] = useState(promoOffer?.oldPrice || '');
  const [variant, setVariant] = useState(promoOffer?.variant || '');

  const [labelText, setLabelText] = useState(promoOffer?.infoLabel?.text || '');
  const [selectedColor, setSelectedColor] = useState(promoOffer?.infoLabel?.color || '');

  const [productFullDescription, setProductFullDescription] = useState(promoOffer?.productFullDescription || null);
  const [dingPromotionFullDescription, setDingPromotionFullDescription] = useState(promoOffer?.dingPromotionFullDescription || null);
  const isEditing = Boolean(promoOffer?.id);
  const offersRepository = new OffersRepository();

  useEffect(() => {
    if (open) {
      setSelectedStatus(promoOffer?.status || 'COMING');
      setVariant(promoOffer?.variant || '');
      setSelectedType(promoOffer?.type || TopUpType.CUBACEL);
      setStartDate(promoOffer?.startDate || '');
      setEndingDate(promoOffer?.endingDate || '');
      setName(promoOffer?.name || '');
      setDescription(promoOffer?.description || '');
      setPrice(promoOffer?.price || '');
      setOldPrice(promoOffer?.oldPrice || '');
      setVariant(promoOffer?.variant || '');
      setLabelText(promoOffer?.infoLabel?.text || '');
      setSelectedColor(promoOffer?.infoLabel?.color || '');
      setValidity(promoOffer?.validity || '');
      setComments(promoOffer?.comments || '');
      setProductFullDescription(promoOffer?.productFullDescription || null);
      setDingPromotionFullDescription(promoOffer?.dingPromotionFullDescription || null);

      console.log('Dialog is open');
      console.log('Promo Offer:', promoOffer);
    }
  }, [open, promoOffer]);

  const handleSave = () => {

    const infoLabel = {
      text: labelText,
      color: selectedColor,
    };

    const newPromoOfferRequest = {
      name: name,
      description: description,
      validity: validity,
      bonus: '',
      price: price,
      vipPrice: price,
      costPrice: price,
      revPercent: 0.5,
      oldPrice: oldPrice,
      type: selectedType,
      status: selectedStatus,
      variant:variant,
      comments: comments,
      infoLabel:infoLabel,
      startDate: parseDate(startDate),
      endingDate: parseDate(endingDate),
      picture: "",
      productFullDescription: productFullDescription,  // Pass along product description
      dingPromotionFullDescription: dingPromotionFullDescription  // Pass along promotion description
    };

    console.log('newPromoOffer:', newPromoOfferRequest);

    if (isEditing) {
      offersRepository.updatePromoOffer(promoOffer.id, newPromoOfferRequest)
        .then(data => {
          console.log('Promo Offer updated:', data);
          onSuccess();
          handleClose();
        })
        .catch(error => {
          console.error('Error updating promo offer:', error);
        });
    } else {
      offersRepository.createPromoOffer(newPromoOfferRequest)
        .then(data => {
          console.log('Promo Offer created:', data);
          onSuccess();
          handleClose();
        })
        .catch(error => {
          console.error('Error creating promo offer:', error);
        });
    }
  };


  function calculatePercentAndAdd(number: number, percentage: number): number {
    // Calculate the percentage value
    const percentValue = (percentage / 100) * number;

    // Add the percentage value to the original number
    const result = number + percentValue;

    return result;
  }


  function formatDate(timestamp: number): string {
    // Create a new Date object with the timestamp
    const date = new Date(timestamp);

    // Get the year, month, and day from the date object
    const year = date.getFullYear();
    // JavaScript months are zero-based, so add 1 to get the correct month
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Create the formatted date string in the format YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  function parseDate(input: string | number): number {
    if (typeof input === "number") {
      // If the input is a timestamp, return it as is
      return input;
    } else if (typeof input === "string") {
      // If the input is a string, assume it's a date in yyyy-mm-dd format
      const [year, month, day] = input.split('-').map(Number);

      // Create a new Date object with the parsed year, month, and day
      const date = new Date(year, month - 1, day); // Month is zero-based

      // Get the timestamp in milliseconds and return it
      return date.getTime();
    } else {
      throw new Error("Invalid input type. Expected a string or a number.");
    }
  }


  function handleClose() {
    onClose();
  }

  function suggestedPrice(price: number): number {
    // Add 3% of the price to the price
    const priceWithPercent = price + (0.03 * price);

    // Add $1.50
    const finalPrice = priceWithPercent + 1.50;

    // Return the result rounded to two decimal places as a number
    return Math.round(finalPrice * 100) / 100;
  }


  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {isEditing ? 'Editar la oferta promocional' : 'Crear una oferta promocional'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              style={{ marginTop: 10 }}
              label="Título"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descripción"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          {/* Price */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Precio"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>

          {/* Old Price */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Precio Sugerido"
              value={suggestedPrice(price)}
              onChange={(e) => setOldPrice(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>

          {/* Type */}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Tipo de oferta</InputLabel>
              <Select
                style={{ marginTop: 10 }}
                // value={`#${Number(selectedColor).toString(16).substring(2)}`}
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                {Object.values(TopUpType).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                    {/*{labelText}*/}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Status */}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Estado de la oferta</InputLabel>
              <Select
                style={{ marginTop: 10 }}
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Start Date */}
          <Grid item xs={5}>
            <TextField
              type="date"
              label="Fecha de inicio"
              value={formatTimestampToUtc(startDate)}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {/* End Date */}
          <Grid item xs={5}>
            <TextField
              type="date"
              label="Fecha de culminación"
              value={formatTimestampToUtc(endingDate)}
              onChange={(e) => setEndingDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {/* Validity */}
          <Grid item xs={2}>
            <TextField
              type="number"
              label="Validés"
              value={validity}
              onChange={(e) => setValidity(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Comentarios"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Grid>

          {/* Label Info Section */}
          <Grid item xs={12} mt={2}>
            <Typography variant="h6">Label Info</Typography>
          </Grid>

          {/* Label Text */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Etiqueta"
              value={labelText}
              onChange={(e) => setLabelText(e.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Color</InputLabel>
              <Select
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
                renderValue={(value) => (
                  <Box
                    component="span"
                    sx={{
                      display: 'inline-block',
                      width: '20px',
                      height: '20px',
                      backgroundColor: `#${Number(value).toString(16).substring(2)}`, // Convierte a hex
                      borderRadius: '4px',
                      marginRight: '8px',
                      verticalAlign: 'middle',
                    }}
                  />
                )}
              >
                <MenuItem value={"0xFFDC143C"}>
                  <Box
                    component="span"
                    sx={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#DC143C',
                      display: 'inline-block',
                      borderRadius: '4px',
                      marginRight: '8px'
                    }}
                  />
                  Rojo
                </MenuItem>
                <MenuItem value={"0xFF1565C0"}>
                  <Box
                    component="span"
                    sx={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#1565C0',
                      display: 'inline-block',
                      borderRadius: '4px',
                      marginRight: '8px'
                    }}
                  />
                  Azul
                </MenuItem>
                <MenuItem value={"0xFF2E7D32"}>
                  <Box
                    component="span"
                    sx={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#2E7D32',
                      display: 'inline-block',
                      borderRadius: '4px',
                      marginRight: '8px'
                    }}
                  />
                  Verde
                </MenuItem>
                <MenuItem value={"0xFF800080"}>
                  <Box
                    component="span"
                    sx={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#800080',
                      display: 'inline-block',
                      borderRadius: '4px',
                      marginRight: '8px'
                    }}
                  />
                  Púrpura
                </MenuItem>
                <MenuItem value={"0xFFFF6D00"}>
                  <Box
                    component="span"
                    sx={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#FF6D00',
                      display: 'inline-block',
                      borderRadius: '4px',
                      marginRight: '8px'
                    }}
                  />
                  Naranja
                </MenuItem>
                <MenuItem value={"0xFFFFD700"}>
                  <Box
                    component="span"
                    sx={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#FFD700',
                      display: 'inline-block',
                      borderRadius: '4px',
                      marginRight: '8px'
                    }}
                  />
                  Amarillo
                </MenuItem>
                <MenuItem value={"0xFF808080"}>
                  <Box
                    component="span"
                    sx={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#808080',
                      display: 'inline-block',
                      borderRadius: '4px',
                      marginRight: '8px'
                    }}
                  />
                  Gris
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <Box
              component="span"

              sx={{
                display: 'flex', // Change display to flex
                alignItems: 'center', // Center vertically
                justifyContent: 'center', // Center horizontally
                padding: '4px 8px', // Reduced padding for a smaller size
                borderRadius: '8px', // Smaller border radius for a more compact look
                fontSize: '0.75rem', // Adjust font size to make the text smaller
                color: '#FFFFFF', // Set text color to white
                maxWidth: '150px',
                minHeight: '40px',
                backgroundColor: `#${Number(selectedColor).toString(16).substring(2)}`, // Convert to valid hex and remove alpha
              }}
            >
              {labelText}
            </Box>
          </Grid>


        </Grid>

        <Box mt={2}>
          <Button variant="contained" onClick={handleSave}>Guardar</Button>
          <Button variant="outlined" onClick={handleClose} sx={{ mx: 1 }}>Cancelar</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

PromoOfferDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  promoOffer: PropTypes.object // Can be optional for creation
};

export default PromoOfferDialog;
