import { Card } from '@mui/material';
import React, { useState } from 'react';
import NetworkCallsRepository from './NetworkCallsRepository';
import NetworkCallsTables from './NetworkCallsTables';
import { NetworkCalls } from 'src/types/models/NetworkCalls';

function NetworkCallsTablesDisplay() {

  return (
    <Card>
      <NetworkCallsTables/>
    </Card>
  );
}

export default NetworkCallsTablesDisplay;
