import React, { useState } from 'react';
import { Paper, Avatar, Typography, Divider, Tooltip, styled, IconButton, lighten } from '@mui/material';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { FullDingPromotion } from '../../types/models/FullDingPromotion';
import { formatDate, formatTimestampToUtc, formatUSD, getUserRole } from '../../utils/utils';
import { FullDingProduct } from '../../types/models/FullDingProduct';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddIcon from '@mui/icons-material/Add';
import PromoOfferDialog from '../pages/Components/Modals/PromoOfferDialog';
import { PromoOffer } from '../../types/models/PromoOffer';
import { UserRole } from '../../types/enums';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

interface ProductCardProps {
  dingProduct: FullDingProduct;
}

const IconButtonError = styled(IconButton)(
  ({ theme }) => `
     background: ${theme.colors.success.lighter};
     color: ${theme.colors.success.main};
     padding: ${theme.spacing(0.5)};

     &:hover {
      background: ${lighten(theme.colors.success.lighter, 0.4)};
     }
`
);

const ProductCard: React.FC<ProductCardProps> = ({ dingProduct }) => {
  const { product, description } = dingProduct;
  const [openEditOfferDialog, setOpenEditOfferDialog] = useState(false);
  const [promoOfferToEdit, setPromoOfferToEdit] = useState(null);
  const roles = localStorage.getItem("rid");
  const handleSave = () => {

  }

  // Function to open the dialog for editing a promo offer
  const handleSaveOffer = () => {
    const promoOffer = new PromoOffer(
      null, //dingProduct.product.localizationKey, // id
      dingProduct.description.displayText, // name
      description.descriptionMarkdown, // description
      '', // userId
      30, // validity (in days)
      '',
      dingProduct.product.minimum.sendValue, // price
      dingProduct.product.minimum.sendValue, // price
      dingProduct.product.minimum.sendValue, // price
      0.7,
      null, // oldPrice
      'CUBACEL', // type
      'COMING', // status: PromoOfferStatus
      'PRODUCT',
      '', // comments
      null,
      Date.now(), // startDate as a timestamp
      Date.now() + 30 * 24 * 60 * 60 * 1000, // endingDate, 30 days from now
      "",
      dingProduct, // productFullDescription
      null, // dingPromotionFullDescription
      Date.now() // createdAt as a timestamp
    );

    setPromoOfferToEdit(promoOffer);
    setOpenEditOfferDialog(true);
  };


  function getCurrentUtcDate(): string {
    const now = new Date(Date.now());

    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function getUtcDateIn30Days(): string {
    const futureDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);

    const year = futureDate.getUTCFullYear();
    const month = String(futureDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(futureDate.getUTCDate()).padStart(2, '0');
    const hours = String(futureDate.getUTCHours()).padStart(2, '0');
    const minutes = String(futureDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(futureDate.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }




  // const handleDeletePromoOffer = (promoOffer) =>{
  //   offersRepository.deletePromoOffer(promoOffer.id).then(data =>
  //     {
  //       console.log('Promo Offer deleted:', data);
  //       handleOffersUpdate();
  //     }
  //   ).catch(error => {
  //     console.error('Error deleting promo offer:', error);
  //     // Handle error as needed
  //   });
  // };
  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenEditOfferDialog(false);
    setPromoOfferToEdit(null);
  };

  const handleOffersUpdate = () => {


    // const fetchPromoOffers = async () => {
    //   offersRepository.fetchPromos().then((data) => {
    //     setPromoOffers(data.data)
    //
    //   }).catch((error) => {
    //     console.error('Failed to fetch offers:', error);
    //   });
    // };
    //
    // fetchPromoOffers();

  };

  return (
    <Paper elevation={1} style={{ margin: '10px', padding: '10px', position: 'relative' }}>
      <Avatar>
        <AssessmentTwoToneIcon />
      </Avatar>
      <div style={{ marginLeft: '10px' }}>
        <Typography variant="h4">{description.displayText}</Typography>
        <Typography variant="h6">{description.descriptionMarkdown}</Typography>
        <Typography>
          Recarga mínima: {formatUSD(product.minimum.sendValue)} {product.minimum.sendCurrencyIso}
        </Typography>
      </div>

      {(getUserRole(roles) === UserRole.ADMIN) && (
        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <Tooltip arrow title="Guardar Oferta">
            <IconButtonError onClick={handleSaveOffer}>
              <AddIcon fontSize="small" />
            </IconButtonError>
          </Tooltip>
        </div>
      )}

      <PromoOfferDialog
        open={openEditOfferDialog}
        onClose={handleCloseDialog}
        promoOffer={promoOfferToEdit}
        onSuccess={handleOffersUpdate}
      />
    </Paper>
  );

};

export default ProductCard;


/*
* <Card className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-14rx78z">
      <CardActionArea className="MuiButtonBase-root MuiCardActionArea-root css-138edws" tabIndex={0} type="button">
        <Avatar className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-1nfqqnw">
          <DirectionsCarTwoToneIcon className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-y21f0p" />
        </Avatar>
        <div className="MuiBox-root css-0">
          <Typography variant="h3" className="MuiTypography-root MuiTypography-h3 css-lque2s">
            MacBook Pro
          </Typography>
          <Typography variant="subtitle2" className="MuiTypography-root MuiTypography-subtitle2 css-1e98l1w">
            You can build unlimited layout styles using any of the 500+ included components and elements. This is a
            powerful and unique template for all modern web technologies.
          </Typography>
        </div>
        <span className="MuiCardActionArea-focusHighlight css-jo3ec3"></span>
        <span className="MuiTouchRipple-root css-w0pj6f"></span>
      </CardActionArea>
    </Card>*/
