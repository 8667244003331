import HttpService from "src/services/api/HttpService";
import { BaseResponse } from '../../types/models/BaseResponse';
import { AuthUser } from '../../types/models/AuthUser';
import { User } from '../../types/models/User';
import { Users, UserStatus } from '../../types/models/Users';
import { Pageable } from '../../types/models/Pageable';
import { BalanceResponse } from '../../types/models/DingConnectBalance';
import { FullDingPromotion } from '../../types/models/FullDingPromotion';
import { FullDingProduct } from '../../types/models/FullDingProduct';


interface ApiResponse {
    data: BalanceResponse;
}

class DingConnectRepository {
    private httpService: HttpService;

    constructor() {
        this.httpService = new HttpService();
        this.httpService.service();
    }

    async deleteUser(userId: string) {
        try {
            const response = await this.httpService.remove<BaseResponse<User>>(
              `/profile/${userId}`
            );
            console.log("deleteUser response", response);
            return response;
        } catch (error) {
            console.error('Error deleting User:', error);
            throw error;
        }
    }

    async fetchAccountBalance(
    ): Promise<BalanceResponse> {
        try {
            const response = await this.httpService.get<any>(`/dingconnect/getBalance`, {
                // params: queryParams
            });
            return response.data;
        } catch (error) {
            console.error('Failed to fetch users:', error);
            throw new Error('Failed to fetch users');
        }
    }

    async fetchFullDingPromotions(): Promise<FullDingPromotion[]> {
        try {
            const response = await this.httpService.get<any>('/dingconnect/getFullDingPromotions');
            return response.data;
        } catch (error) {
            console.error('Failed to fetch full ding promotions:', error);
            throw new Error('Failed to fetch full ding promotions');
        }
    }

    async fetchFullDingProducts(): Promise<FullDingProduct[]> {
        try {
            const response = await this.httpService.get<any>('/dingconnect/getFullDingProducts');
            return response.data;
        } catch (error) {
            console.error('Failed to fetch full ding promotions:', error);
            throw new Error('Failed to fetch full ding promotions');
        }
    }
}

export default DingConnectRepository;
