import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import TopBarContent from './TopBarContent';
import BottomBarContent from './BottomBarContent';
import SidebarContent from './SidebarContent';
import ChatContent from './ChatContent';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import Scrollbar from 'src/components/Scrollbar';
import {
  Box,
  styled,
  Divider,
  Drawer,
  IconButton,
  useTheme
} from '@mui/material';
import MessengerRepository, { ChatInfo, ChatUser } from './MessengerRepository';
import { useAuth } from '../../../hooks/useAuth';

const RootWrapper = styled(Box)(
  ({ theme }) => `
    height: calc(100vh - ${theme.header.height});
    display: flex;
  `
);

const Sidebar = styled(Box)(
  ({ theme }) => `
    width: 300px;
    background: ${theme.colors.alpha.white[100]};
    border-right: ${theme.colors.alpha.black[10]} solid 1px;
  `
);

const ChatWindow = styled(Box)(
  () => `
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  `
);

const ChatTopBar = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
    padding: ${theme.spacing(2)};
    align-items: center;
  `
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    background: ${theme.colors.alpha.white[100]};
  `
);

const DrawerWrapperMobile = styled(Drawer)(
  () => `
    width: 340px;
    flex-shrink: 0;

    & > .MuiPaper-root {
      width: 340px;
      z-index: 3;
    }
  `
);

function ApplicationsMessenger() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [chats, setChats] = useState<ChatInfo[]>([]);
  const [selectedChat, setSelectedChat] = useState<ChatInfo | null>(null);
  const messengerRepository = new MessengerRepository();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [chatUser, setChatUser] = useState<ChatUser | null>(null);
  const [randomData, setRandomData] = useState('');
  const [chatId, setChatId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const userId = localStorage.getItem("id"); // Replace with actual user ID

  // State to manage WebSocket connections for multiple users
  const [userSessions, setUserSessions] = useState<Map<string, WebSocket>>(new Map());

  // Function to initialize WebSocket for a user
  const initializeWebSocket = (userId: string) => {
    const url = new URL(`${process.env.REACT_APP_WEBSOCKET_URL}/ws/chat?userId=${userId}`);
    const socket = new WebSocket(url.toString());

    socket.onopen = () => {
      console.log(`WebSocket connection established for user ${userId}`);
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (selectedChat && message.chatId === selectedChat.id) {
        // Update the selected chat with the new message
        setSelectedChat(prevChat => {
          if (prevChat) {
            return {
              ...prevChat,
              conversation: [...prevChat.conversations, message],
            };
          }
          return prevChat;
        });
      }
      setRandomData(event.data);
    };

    socket.onclose = () => {
      console.log(`WebSocket connection closed for user ${userId}`);
      setUserSessions(prevSessions => {
        const newSessions = new Map(prevSessions);
        newSessions.delete(userId);
        return newSessions;
      });
    };

    setUserSessions(prevSessions => new Map(prevSessions).set(userId, socket));
  };

  useEffect(() => {
    if (userId) {
      initializeWebSocket(userId);
    }
    return () => {
      const socket = userSessions.get(userId);
      if (socket) {
        socket.close();
      }
    };
  }, [userId]);

  useEffect(() => {
    if (selectedChat) {
      const chatUser: ChatUser = {
        customerName: selectedChat.customerName || localStorage.getItem("username"),
        customerPhotoUrl: selectedChat.customerPhotoUrl || localStorage.getItem("photoURL"),
        lastUpdated: selectedChat.lastUpdated || new Date().toISOString()
      };
      setChatUser(chatUser);
      setCustomerId(selectedChat.customerId);
      setChatId(selectedChat.id);
    }
  }, [selectedChat]);

  useEffect(() => {
    messengerRepository.fetchChats(page, limit).then((data) => {
      setChats(data.content);
      setTotalItems(data.totalElements);
    }).catch((error) => {
      console.error('Failed to fetch chats:', error);
    });
  }, [page, limit]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSendMessage = (message: string) => {
    if (userId && userSessions.has(userId)) {
      const socket = userSessions.get(userId);
      if (socket) {
        const email = localStorage.getItem('email'); // Ensure this is available
        const jsonMessage = JSON.stringify({
          chatId: chatId,
          senderId: userId,
          customerId: customerId,
          messageText: message,
        });
        console.log('Message to send:', jsonMessage);
        socket.send(jsonMessage); // Send message via WebSocket

        // Update the selectedChat with the new message
        setSelectedChat(prevChat => {
          if (prevChat) {
            return {
              ...prevChat,
              conversation: [...prevChat.conversations, {
                chatId,
                senderId: userId,
                customerId,
                messageText: message,
                timestamp: new Date().toISOString()
              }],
            };
          }
          return prevChat;
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Messenger - Applications</title>
      </Helmet>
      <RootWrapper className="Mui-FixedWrapper">
        <DrawerWrapperMobile
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <Scrollbar>
            <SidebarContent chats={chats} onChatSelect={setSelectedChat} />
          </Scrollbar>
        </DrawerWrapperMobile>
        <Sidebar
          sx={{
            display: { xs: 'none', lg: 'inline-block' }
          }}
        >
          <Scrollbar>
            <SidebarContent chats={chats} onChatSelect={setSelectedChat} />
          </Scrollbar>
        </Sidebar>
        <ChatWindow>
          <ChatTopBar
            sx={{
              display: { xs: 'flex', lg: 'inline-block' }
            }}
          >
            <IconButtonToggle
              sx={{
                display: { lg: 'none', xs: 'flex' },
                mr: 2
              }}
              color="primary"
              onClick={handleDrawerToggle}
              size="small"
            >
              <MenuTwoToneIcon />
            </IconButtonToggle>
            <TopBarContent chatUser={chatUser} />
          </ChatTopBar>
          <Box flex={1}>
            <Scrollbar>
              <ChatContent chat={selectedChat} />
            </Scrollbar>
          </Box>
          <Divider />
          <BottomBarContent onSendMessage={handleSendMessage} />
        </ChatWindow>
      </RootWrapper>
    </>
  );
}

export default ApplicationsMessenger;
